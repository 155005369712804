import React from "react";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSelector,useDispatch } from "react-redux";
import { requestSearchAccountDelete } from "../redux/system/system";
const AccountHistory=({setAccountId})=>{
    let dispatch=useDispatch();
    let {system} = useSelector(state=>state);
    return(
        <div style={{overflowX: "scroll", width: "100%",display: "flex",justifyContent: "flex-start" , flexWrap: "nowrap", padding: "2px 10px 2px 10px", margin: "15px 0px 15px 0px"}}>
            {system.searchAccountList.map((account,accountIndex)=>(
                <div onClick={()=>{
                    setAccountId(account);
                }} style={{display:"flex",margin: "0px 5px 0px 5px" ,padding: "5px 10px 5px 10px",border: "2px solid gray", color: "gray", borderRadius: "7px",cursor: "pointer",width: "auto",alignItems: "center"}}>
                    {account}<span  style={{display: "flex", alignItems: 'center'}} onClick={()=>{
                        dispatch(requestSearchAccountDelete(accountIndex));
                    }}><HighlightOffIcon fontSize="small" style={{height: "20px", width: "20px",marginLeft: "4px"}} /></span>
                </div>
            ))}
        </div>
    );
}
export default AccountHistory;
import React from "react";
const Title=({main,sub})=>{
    return(
        <div style={{display: 'flex', alignItems: "flex-start", flexDirection: "column",marginBottom: "20px"}}>
            <div style={{fontSize: "15px", fontWeight: 400}}>{sub}</div>
            <div style={{display: "flex", margin: "20px 0px 0px 10px"}}>
                <div className="category">{main}</div>
            </div>
        </div>
    )
}
export default Title;
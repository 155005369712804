import React, { useState,useEffect } from "react";
// import { Dropdown } from 'react-bootstrap';
import { GiHamburgerMenu } from "@react-icons/all-files/gi/GiHamburgerMenu";
import { Link } from "react-router-dom";
import "./css/SideBar.css";


function SideBar() {
  let [isMenuOn, setIsMenuOn] = useState(false);
  let [menu1, setMenu1] = useState(false);
  let [menu2, setMenu2] = useState(false);
  let [menu3, setMenu3] = useState(false);
  let [menu4, setMenu4] = useState(false);
  let [menu5, setMenu5] = useState(false);

  useEffect(()=>{
    if(window.Kakao.Auth==undefined){
      window.Kakao.cleanup();
      window.Kakao.init("fe3e5712fe3356a869e3b2fc4c1ebd37");
    }
  },[])

  // useEffect(()=>{
  //   {console.log(window.Kakao.Auth.getStatusInfo())}
  // });

  function togleHandler() {
    if (isMenuOn) {
      setIsMenuOn(false);
    } else {
      setIsMenuOn(true);
    }
  }

  function menuHandler1() {
    if (menu1) {
      setMenu1(false);
    } else {
      setMenu1(true);
    }
  }

  function menuHandler2() {
    if (menu2) {
      setMenu2(false);
    } else {
      setMenu2(true);
    }
  }

  function menuHandler3() {
    if (menu3) {
      setMenu3(false);
    } else {
      setMenu3(true);
    }
  }

  function menuHandler4() {
    if (menu4) {
      setMenu4(false);
    } else {
      setMenu4(true);
    }
  }

  function menuHandler5() {
    if (menu5) {
      setMenu5(false);
    } else {
      setMenu5(true);
    }
  }

  return (
    <div id="SideBar">
      <div id="sideBarTogle">
        {isMenuOn ? <span id="icon" onClick={togleHandler}>ICON</span> : <GiHamburgerMenu id="togle" onClick={togleHandler} />}
      </div>
      { isMenuOn ? <div id="sideBarContainer">
        <ul id="menuCotainer">
          <li onClick={menuHandler1}>서비스 <span className="plusMinus">{menu1 ? "-" : "+"}</span></li>
          {menu1 ? <ul>
            <Link to="/sellerbox-admin.cplat.io/service/sales">
              <li>매출</li>
            </Link>
            <Link to="/sellerbox-admin.cplat.io/service/calculate">
              <li>정산</li>
            </Link>
            <Link to="/sellerbox-admin.cplat.io/service/buyer">
              <li>구매자</li>
            </Link>
            <Link to="/sellerbox-admin.cplat.io/service/delivery">
              <li>배송</li>
            </Link>
            <Link to="/sellerbox-admin.cplat.io/service/refund">
              <li>반품</li>
            </Link>
            <Link to="/sellerbox-admin.cplat.io/service/price">
              <li>단가</li>
            </Link>
          </ul> : null}
          <li onClick={menuHandler2}>사용자 <span className="plusMinus">{menu2 ? "-" : "+"}</span></li>
          {menu2 ? <ul>
            <Link to="/sellerbox-admin.cplat.io/main">
              <li>셀러</li>
            </Link>
            <Link to="/sellerbox-admin.cplat.io/user/market">
              <li>연동마켓</li>
            </Link>
            <Link to="/sellerbox-admin.cplat.io/user/inquiry">
              <li>문의사항</li>
            </Link>
          </ul> : null}
          <li onClick={menuHandler3}>통계 <span className="plusMinus">{menu3 ? "-" : "+"}</span></li>
          {menu3 ? <ul>
            <Link to="/sellerbox-admin.cplat.io/main">
              <li>접속통계</li>
            </Link>
            <Link to="/sellerbox-admin.cplat.io/main">
              <li>현재 접속자</li>
            </Link>
          </ul> : null}
          <li onClick={menuHandler4}>푸시메시지 <span className="plusMinus">{menu4 ? "-" : "+"}</span></li>
          {menu4 ? <ul>
            <Link to="/sellerbox-admin.cplat.io/pushNotification/sendPushNotification">
              <li>메시지 발송</li>
            </Link>
            <Link to="/sellerbox-admin.cplat.io/pushNotification/pushNotificationHistory">
              <li>발송이력</li>
            </Link>

          </ul> : null}
          <li onClick={menuHandler5}>일반 <span className="plusMinus">{menu5 ? "-" : "+"}</span></li>
          {menu5 ? <ul>
            <Link to="/sellerbox-admin.cplat.io/general/term">
              <li>약관</li>
            </Link>
            <Link to="/sellerbox-admin.cplat.io/general/notice">
              <li>공지사항</li>
            </Link>
            <Link to="/sellerbox-admin.cplat.io/main">
              <li>게시판</li>
            </Link>
            <Link to="/sellerbox-admin.cplat.io/main">
              <li>권한</li>
            </Link>
          </ul> : null}
        </ul>
      </div> : null}
    </div>
  );
}

export default SideBar;

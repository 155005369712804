import React, { useEffect, useState } from "react";
import "../css/all.css";
import SideBar from "../SideBar";
import { BsSearch } from 'react-icons/bs';
import { RiFileExcel2Line } from 'react-icons/ri';
import Table from "../../component/Table";
import { withRouter } from "react-router-dom";
import Range from "../../component/Range";
import Categories from "../../component/Categories";
import AccountHistory from "../../component/AccountHistory";
import * as api from "../../controller/api";
import Title from "../../component/Title";
import {requestMessageModalOpen,requestSearchAccountPush} from "../../redux/system/system";
import {useDispatch,useSelector} from "react-redux";

function Inquiry(props) {
  let { system } = useSelector(state=>state);
  let dispatch=useDispatch();
  let [record, setRecord] = useState(32); // 검색 결과 갯수;
  let [selectedCategory,setSelectedCategory]=useState(0);  //선택된 카테고리
  let [selectedCompany,setSelectedCompnay]=useState(null);
  let [selectedRange,setSelectedRange]=useState(null);  //선택한 범위(버튼 on off 용도)
  let [startRange,setStartRange]=useState(""); //실제 범위(time type:string ex: YYYY-MM-DD) 이날 00시부터
  let [endRange,setEndRange]=useState("")  //실제 범위(time type:string ex: YYYY-MM-DD)  이날 24시까지
  let [tableData,setTableDate]=useState({});  //page,count,filter 3가지(변경되면 바로 쿼리 쏴줘야함)!!!!!
  let [accountId,setAccountId]=useState("");
  let [columns,setColumns]=useState(
    [  
        { field: '0', name: '#', match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [],isAccordion:false  },
        { field: '1', name: '셀러', match: "reg_id", width: 170, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [],isAccordion:false  },
        { field: '2', name: '이메일', match: "email", width: 170, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [],isAccordion:false  },
        { field: '3', name: '작성일',match : "reg_dt", width: 200, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [],isAccordion:false  },
        { field: '4', name: '답변자', match: "chg_id", width: 170, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [],isAccordion:false  },
        { field: '5', name: '답변 완료일',match : "chg_dt", width: 200, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [],isAccordion:false  },
        { field: '6', name: '상태',match: "answer_yn", width: 200, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [],isAccordion:false  },
        { field: '7', name: '문의 유형',match: "inquiry_type", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
        { field: '8', name: '',match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [],isAccordion:false },
      ]
  );

  let [rows,setRows]=useState([null]);
    let [rowDetail,setRowDetail]=useState(null);
  let [isNeedToSearch,setIsNeedToSearch]=useState(true);

  useEffect(()=>{
    if(isNeedToSearch==false){
      sendApi();
    }
  },[tableData])

  const sendApi=()=>{
      console.log(selectedCategory)
    api.getInquiry({
      selectedCategory,
      range: {
        startRange,
        endRange
      },
      tableData,
      accountId
    })
    .then(res=>{
      if(res.data.data==null || res.data.data.length==0){
        dispatch(requestMessageModalOpen({type:"success",message:`문의사항 내역이 없습니다.`}));
        dispatch(requestSearchAccountPush(accountId));
      }
          setRows(res.data.data.map((d,di)=>{return [
            tableData.page*tableData.count+di,
            d.tbl_user.email,
            d.email,
            d.reg_dt.split("T")[0],
            (d.chg_id==d.user_no || !d.chg_id) ? null : d.chg_id,
            d.chg_dt!==d.reg_dt ? d.chg_dt.split("T")[0] : null,
            d.answer_yn,
            d.title,
            "+"
          ]}));
          setRowDetail(
            res.data.data.map((d,di)=>{return {
                answer: d.answer,
                email: d.email,
                title:d.title,
                context:d.context,
                seq_no: d.seq_no,
                user_no:d.user_no
            }})
          );
      
    })
    .catch(err=>{
      dispatch(requestMessageModalOpen({type:"error",message:err.response?.data?.message}));
    })
  }

  const changeInquiry=(data)=>{
    api.changeInquiry(data)
    .then(res=>{
        dispatch(requestMessageModalOpen({type:"success",message: "답변달기 성공"}));
        sendApi();
    })
    .catch(err=>{
        dispatch(requestMessageModalOpen({type:"error",message:err.response?.data?.message}));
    });
  }

  return (
    <div className="frame">
      <SideBar />
      <div id="mainContentsContainer">
        <Title main={"문의사항"} sub={"사용자 ▶︎ 문의사항"} />

        <div className="subContentsContainer">

            <Categories target={"inquiry"} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} selectedCompany={null} setSelectedCompnay={null} isNeedToSearch={isNeedToSearch} setIsNeedToSearch={setIsNeedToSearch}/>
            <Range selectedRange={selectedRange} setSelectedRange={setSelectedRange} startRange={startRange} setStartRange={setStartRange} endRange={endRange} setEndRange={setEndRange}/>

            <div className="option4Container">
              <input type="text" placeholder="셀러 계정" value={accountId} 
                onKeyPress={(e)=>{
                  if(e.key=="Enter"){
                    sendApi();
                    setIsNeedToSearch(false);
                  } 
                }}
                onChange={(e)=>{
                  setAccountId(e.target.value)
                }}
              /><span onClick={()=>{sendApi();setIsNeedToSearch(false);}} className="searchIconBox"><BsSearch /></span>
            </div>
            <AccountHistory setAccountId={setAccountId}/>
        </div>
        <Table isAccordion={"inquiry"} rowDetail={[rowDetail,rowDetail]} isCheckRow={false} head={[columns,setColumns]} body={[rows,setRows]} control={[tableData,setTableDate]} selectedCompany={selectedCompany} isNeedToSearch={isNeedToSearch} setIsNeedToSearch={setIsNeedToSearch}  submitHandle={changeInquiry}/>
      </div>
    </div >
  );
}

export default withRouter(Inquiry);
import React, { useState } from "react";
import "../css/Main.css";


function Review() {

  let [graph, setGraph] = useState(true);

  function graphHandler() {
    if (window.innerWidth == 500) {
      console.log(window.innerWidth);
      setGraph(false);
    }
    if (window.innerWidth > 500) {
      setGraph(true);
    }
  }

  window.addEventListener("resize", graphHandler);

  // container two 데이터, 별 점 갯수에 따른 그래프 길이
  let [rating, setRating] = useState("4.6"); // 평점
  let [start5, setStart5] = useState(150); // 별 점 5개 비례
  let [start4, setStart4] = useState(100); // 별 점 4개 비례
  let [start3, setStart3] = useState(20); // 별 점 3개 비례
  let [start2, setStart2] = useState(10); // 별 점 2개 비례
  let [start1, setStart1] = useState(40); // 별 점 1개 비례
  let [allReview, setAllReview] = useState("4,133"); // 총 평가 갯수


  return (
    <div class="two">
      <div className="font1">앱 리뷰 현황</div>
      <div>
        <div>
          <div className="font2" >평점</div>
          <div>{rating}</div>
        </div>
        <div>
          <div style={{ display: "flex", alignItems: "center", marginTop: 50 }}><span style={{ color: "#666666" }}>✭✭✭✭✭</span><div className="stickGraph" style={{ width: start5, marginLeft: 17 }}></div><div className="reviewAmount">{start5}</div></div>
          <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}><span style={{ color: "#666666" }}>✭✭✭✭</span><div className="stickGraph" style={{ width: start4, marginLeft: 30 }}></div><div className="reviewAmount">{start4}</div></div>
          <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}><span style={{ color: "#666666" }}>✭✭✭</span><div className="stickGraph" style={{ width: start3, marginLeft: 44 }}></div><div className="reviewAmount">{start3}</div></div>
          <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}><span style={{ color: "#666666" }}>✭✭</span><div className="stickGraph" style={{ width: start2, marginLeft: 57 }}></div><div className="reviewAmount">{start2}</div></div>
          <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}><span style={{ color: "#666666" }}>✭</span><div className="stickGraph" style={{ width: start1, marginLeft: 70 }}></div><div className="reviewAmount">{start1}</div></div>
          <div style={{ marginTop: 20, marginLeft: 150, color: "gray", }}>총 {allReview} 평가</div>
        </div>
      </div>
    </div>
  );
}

export default Review;




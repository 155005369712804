import React, { useState,useEffect } from "react"
import "../../src/views/css/all.css"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
// import { useMediaQuery } from "@mui/material";
const TermBox=({terms,setTerms,index})=>{
    // let under_500=useMediaQuery('(max-width:500px)');
    // let under_1250=useMediaQuery('(max-width:1250px)');
    // const [innerTitle,setInnerTitle]=useState("");
    // const [innerDetail,setInnerDetail]=useState("");
    // const [innerIsNeed,setInnerIsNeed]=useState(false);
    // useEffect(()=>{
    //     if(dataSet!==null){
    //         setInnerTitle(dataSet.title);
    //         setInnerDetail(dataSet.detail);
    //         setInnerIsNeed(dataSet.isNeed);
    //     }
    // },[])
    const handleTitle=(data)=>{
        let tmp=[...terms];
        tmp[index].title=data;
        setTerms(tmp);
    }
    const handleIsNeed=(data)=>{
        let tmp=[...terms];
        tmp[index].isNeed=data;
        setTerms(tmp);
    }
    const handleDetail=(data)=>{
        let tmp=[...terms];
        tmp[index].detail=data;
        setTerms(tmp);
    }

    const handleDelete=()=>{
        let tmp=terms.filter((d,i)=>i!==index);
        console.log(tmp)
        setTerms(tmp);
    }

    return(
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column",padding: "20px 0px 20px 0px"}}>
            <div style={{width: "99%",marginLeft: "10px",display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                    <input className="noCssInput" value={terms[index].title} onChange={(e)=>{handleTitle(e.target.value);}} type="text" style={{borderBottom: "1px solid black"}}  />
                    <span onClick={()=>{handleIsNeed(true);}} style={{display: "flex", alignItems: "center",fontSize: "18px",cursor: "pointer"}}>{terms[index].isNeed ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}{"필수"}</span>
                    <span onClick={()=>{handleIsNeed(false);}} style={{display: "flex", alignItems: "center",fontSize: "18px",cursor: "pointer"}}>{terms[index].isNeed ? <CheckCircleOutlineIcon /> : <CheckCircleIcon />}{"선택"}</span>
                </div>
                <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                    <div onClick={handleDelete} style={{cursor: "pointer",paddingTop: "2px",width: "20px",height: "20px",display:"flex",alignItems: "center", justifyContent: "center", backgroundColor: "#000000", color: "#FFFFFF", fontWeight: 700}}>{"X"}</div>
                </div>
            </div>
            <div  style={{width: "99%",height: "200px",margin: "10px 0px 10px 0px", border: "2px solid gray", borderRadius: "7px",padding: "4px"}}>
                <textarea className="noCssTextarea" value={terms[index].detail} onChange={(e)=>{handleDetail(e.target.value)}} style={{width: "100%",height: "196px"}} />
            </div>
        </div>
    );
}
export default TermBox;
import React, { useEffect } from "react";
import "../views/css/all.css";
const Range=({selectedRange,setSelectedRange,startRange,setStartRange,endRange,setEndRange, defaultRange})=>{


    const dateToString=(dateObject)=>`${
        dateObject.getFullYear()
    }-${
        (dateObject.getMonth()+1)<10 ? "0"+(dateObject.getMonth()+1) : dateObject.getMonth()+1
    }-${
        dateObject.getDate()<10 ? "0"+(dateObject.getDate()) : dateObject.getDate()
    }`;

    const handleDate=(date,isDefault)=>{
        if(isDefault){
            setSelectedRange(date);
        }else{
            setSelectedRange(selectedRange==date ? null : date);
        }
        let startDay=new Date();
        let endDay=new Date();
        let offset=0;
    
    
        switch(date){
            case "day":
                offset=0;
                break;
            case "week":
                offset=24*6;
                break;
            case "month":
                offset=24*30; //한달 31일 계산
                break;
            case "3month":
                offset=24*90;
                break;
        }
        startDay=new Date(startDay.getTime()-3600000*offset);
        setStartRange(dateToString(startDay));
        setEndRange(dateToString(endDay));
      }

      useEffect(()=>{
          console.log("dayday")
          console.log(defaultRange)
          if(defaultRange){
            handleDate(defaultRange,true);
          }else{
              setStartRange(dateToString(new Date()));
              setEndRange(dateToString(new Date()));
          }
    },[])


    return (
        <>
            <div className="option2Container" >
                <span className={selectedRange=="day" ? "selected" : ""} onClick={(e)=>{handleDate("day");}}>오늘</span>
                <span className={selectedRange=="week" ? "selected" : ""} onClick={(e)=>{handleDate("week");}}>1주일</span>
                <span className={selectedRange=="month" ? "selected" : ""} onClick={(e)=>{handleDate("month");}}>1개월</span>
                <span className={selectedRange=="3month" ? "selected" : ""} onClick={(e)=>{handleDate("3month");}}>3개월</span>
            </div>
            <div className="option3Container">
                <input type="date" id="start" name="trip-start" value={startRange} onChange={(e)=>{setSelectedRange(null);setStartRange(e.target.value);}} /><span>~</span><input type="date" id="start" name="trip-start" value={endRange} onChange={(e)=>{setSelectedRange(null);setEndRange(e.target.value);}} />
            </div>
        </>
    )
}
export default Range;
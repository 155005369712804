import React, { useEffect, useState } from "react";
import "../css/all.css";
import SideBar from "../SideBar";
import { BsSearch } from 'react-icons/bs';
import { RiFileExcel2Line } from 'react-icons/ri';
import Table from "../../component/Table";
import { withRouter } from "react-router-dom";
import Range from "../../component/Range";
import Categories from "../../component/Categories";
import * as api from "../../controller/api";

function User(props) {

  let [record, setRecord] = useState(32); // 검색 결과 갯수;
  let [selectedCategory,setSelectedCategory]=useState(0);  //선택된 카테고리
  let [selectedCompany,setSelectedCompnay]=useState(null);
  let [selectedRange,setSelectedRange]=useState(null);  //선택한 범위(버튼 on off 용도)
  let [startRange,setStartRange]=useState(""); //실제 범위(time type:string ex: YYYY-MM-DD) 이날 00시부터
  let [endRange,setEndRange]=useState("")  //실제 범위(time type:string ex: YYYY-MM-DD)  이날 24시까지
  let [tableData,setTableDate]=useState({});  //page,count,filter 3가지(변경되면 바로 쿼리 쏴줘야함)!!!!!
  let [accountId,setAccountId]=useState("");
  let [columns,setColumns]=useState([  //상단 고정값
    { field: '0', name: '셀러', width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
    { field: '1', name: '주문일', width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
    { field: '2', name: '주문번호', width: 100, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
    { field: '3', name: '주문상태', width: 100, isSort:false, sortDirection: "none" ,isCheckbox: true ,checkboxList: ["결제완료","배송중","배송완료","구매확정","교환접수","반품접수","교환완료","반품완료"], checkboxData: new Array(8).fill(true) },
    { field: '4', name: '상품', width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
    { field: '5', name: '수량', width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
    { field: '6', name: '순이익', width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
    { field: '7', name: '원가', width: 100, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
    { field: '8', name: '수수료', width: 100, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
    { field: '9', name: '매출액', width: 100, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
    { field: '10', name: '지불방식', width: 100, isSort:false, sortDirection: "none" ,isCheckbox: true ,checkboxList: ["카드","현금","간편결제"], checkboxData: new Array(3).fill(true) },
    { field: '11', name: '마켓', width: 100, isSort:false, sortDirection: "none" ,isCheckbox: true ,checkboxList: ["네이버","에이블리","쿠팡"], checkboxData: new Array(3).fill(true) },
  ]);
  let [rows,setRows]=useState([  //결과(이건 뭐 바꿀게 없음(그냥 받아와서 뿌려주면 됨))
    [
      2,3,4,5,6,7,8,9,10,11,12,13
    ],
    [
      2,3,4,5,6,7,8,9,10,11,12,13
    ],
    [
      2,3,4,5,6,7,8,9,10,11,12,13
    ]
  ]);

  const sendApi=()=>{
    api.getBuyer({
      selectedCategory,
      range: {
        startRange,
        endRange
      },
      tableData,
      accountId
    })
    .then(res=>{
      console.log(res.data);
    })
    .catch(err=>{
      console.log(123);
    })
  }

  return (
    <div className="frame">
      <SideBar />
      <div id="mainContentsContainer">
        <div className="route">서비스 ▶︎ 정산</div>
        <div className="subContentsContainer">
            <div style={{display: "flex"}}>
                <div className="category">정산</div>
            </div>

            <Categories selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} selectedCompany={selectedCompany} setSelectedCompnay={setSelectedCompnay} />
            <Range selectedRange={selectedRange} setSelectedRange={setSelectedRange} startRange={startRange} setStartRange={setStartRange} endRange={endRange} setEndRange={setEndRange}/>

          <div className="option4Container">
          <input type="text" placeholder="셀러 계정" value={accountId} onChange={(e)=>{setAccountId(e.target.value)}} /><span onClick={sendApi} className="searchIconBox"><BsSearch /></span>
          </div>
          <div className="excelResultContainer"><span className="result">Result of # {record}</span><span className="excel"><RiFileExcel2Line /><span>XLS 내려받기</span></span></div>
        </div>
        <Table isCheckRow={false} head={[columns,setColumns]} body={[rows,setRows]} control={[tableData,setTableDate]} />
      </div>
    </div >
  );
}

export default withRouter(User);
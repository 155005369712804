import React, { useState } from "react";
import { PieChart } from 'react-minimal-pie-chart';
import "../css/Main.css";

function Download() {
  // os 별 그래프 색깔
  const IOSColor = 'wheat';
  const AndroidColor = '#3d7f4d';

  // container one 데이터
  let [IOS, setIOS] = useState(68); // IOS 다운로드 퍼센테이지
  let [Android, setAndroid] = useState(32); // Android 다운로드 퍼센테이지
  let [today, setToday] = useState("20,019");// 오늘 다운로드 수
  let [comWithYester, setComWithYester] = useState("+148");// 어제 대비
  let [thisMonth, setThisMonth] = useState("92,019");// 이번달
  let [comWithLastMonth, setComWithLastMonth] = useState("+11,873"); // 지난달 대비 
  let [acculmulated, setAcculmulated] = useState("120,000"); // 누적 다운로드 수

  return (
    <div class="one">
      <div>
        <div className="font1">앱 다운로드 현황</div>
        <div className="font2" style={{ marginLeft: 20 }}>오늘 {today}</div>
        <div className="font3" style={{ marginLeft: 20 }}>어제대비 {comWithYester}</div>
        <div className="font2" style={{ marginLeft: 20 }}>이번달 {thisMonth}</div>
        <div className="font3" style={{ marginLeft: 20 }}>지난달대비 {comWithLastMonth}</div>
        <div className="font2" style={{ marginLeft: 20 }}>누적 다운로드 {acculmulated}</div>
      </div>
      <div>
        <div style={{ display: "flex", alignItems: "center", justifyItems: "center" }}>
          <span style={{ width: 27, height: 15, backgroundColor: IOSColor, display: "block", marginRight: 10 }}></span><span className="font3">iOS</span><span style={{ fontSize: 17, fontWeight: 700, marginTop: 10, marginLeft: 40 }}> {IOS}%</span>
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyItems: "center" }}>
          <span style={{ width: 27, height: 15, backgroundColor: AndroidColor, display: "block", marginRight: 10 }}></span><span className="font3">Android</span><span style={{ fontSize: 17, fontWeight: 700, marginTop: 10, marginLeft: 14 }}> {Android}%</span>
        </div>
        <PieChart
          className="circleGraph"
          style={{ width: 180, marginTop: 20 }}
          data={[
            { value: IOS, color: IOSColor },
            { value: Android, color: AndroidColor },
          ]}
        />
      </div>
    </div>
  );
}

export default Download;









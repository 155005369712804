import React, { useEffect,useRef,useState } from "react";

let pushHistoryCategories=[
    {name:"작성자", query:"reg_id"},
    {name:"대상자", query:"target_id"},
    {name:"제목", query:"title"},
    {name:"내용", query:"context"},
    // {name: "작성일", query: "reg_dt"},
    // {name: "답변 완료일", query: "chg_dt"}
]

let inquiryCategories=[
    {name:"작성자", query:"reg_id"},
    {name:"수정자", query:"chg_id"},
    // {name: "작성일", query: "reg_dt"},
    // {name: "답변 완료일", query: "chg_dt"}
]

let compnayCategories=[
    {name:"스마트스토어", query: "smartstore"},
    {name:"쿠팡", query: "coupang"},
    {name:"옥션", query: "auction"},
    {name: "G마켓",query:"gmarket"},
]

let noticeCategories=[
    // {name:"전체", query:"ALL"},
    {name:"작성자", query:"reg_id"},
    {name:"수정자", query:"chg_id"},
    // {name: "작성일", query: "reg_dt"},
]

let salesCategories={
    "smartstore": [
        {name:"전체", query:"ALL"},
        {name:"배송완료", query:"배송완료"},
        {name:"배송중", query:"배송중"},
        {name:"결제완료", query:"결제완료"},
        {name:"구매확정", query:"구매확정"},
        {name:"결제대기", query:"결제대기"},
        {name:"교환", query:"교환"},
        {name:"반품", query:"반품"},
        {name:"미결제취소", query:"미결제취소"},
        {name:"취소", query:"취소"},
    ],
    "coupang": [  
        {name:"전체", query:"ALL"},
        {name:"배송완료",query: "FINAL_DELIVERY"},
        {name:"결제완료", query:"ACCEPT"},
        {name:"결제완료",query: "INSTRUCT"},
        {name:"배송지시",query: "DEPARTURE"},
        {name:"배송중",query: "DELIVERING"},
        {name:"업체 직접 배송",query: "NONE_TRACKING"}	
    ],
    "auction": [
        {name:"전체", query:"ALL"},
    ],
    "gmarket": [
        {name:"전체", query:"ALL"},
    ],
}
const Categories = ({selectedCategory,setSelectedCategory,selectedCompany,setSelectedCompnay,isNeedToSearch,setIsNeedToSearch,target,isNeedCompany})=>{
    let[topCategories,setTopCategories]=useState(
        target=="sales" ? salesCategories :
        target=="inquiry" ? inquiryCategories :
        target=="notice" ? noticeCategories :
        target=="pushHistory" ? pushHistoryCategories :
        null
    );

    // let topCategories = target=="sales" ? salesCategories : target=="inquiry" ? inquiryCategories : null;
    // useEffect(()=>{
    //     setTopCategories(target=="sales" ? 
    //     salesCategories :
    //     target=="inquiry" ?
    //     inquiryCategories :
    //     null)
    // })

    useEffect(()=>{
        if(["sales"].indexOf(target)!==-1){  //세일즈
            if(setSelectedCategory && selectedCategory){
                setSelectedCompnay(compnayCategories[0].query);
            }
            setSelectedCategory(topCategories[compnayCategories[0]?.query]?.query);
        }else{  //문의사항 등 카테고리 하나인것만
            setSelectedCategory(topCategories[0].query);
        }
    },[]);

    useEffect(()=>{
        if(selectedCompany && topCategories[selectedCompany][0]){
            if(setSelectedCategory && selectedCategory){
                setSelectedCategory(topCategories[selectedCompany][0].query);
            }
            setIsNeedToSearch(true);
        }
    },[selectedCompany])
    
    return(
        <>
        <span>
        <select className="option1" onChange={(e)=>{setSelectedCategory(e.target.value)}} >
            {
                ["sales"].indexOf(target)!==-1 
                ?
                    topCategories[selectedCompany]?.map((category)=><option selected={category.query==selectedCategory} value={category.query} >{category.name}</option>)
                :
                    topCategories?.map((category)=><option selected={category.query==selectedCategory} value={category.query} >{category.name}</option>)
            }
        </select>
        </span>
        {
            isNeedCompany &&
            <span style={{marginLeft: "20px"}}>
                <select className="option1" onChange={(e)=>{setSelectedCompnay(e.target.value)}}>
                    {
                    compnayCategories?.map((category)=><option selected={category.query==selectedCompany} value={category.query} >{category.name}</option>)
                    }
                </select>
            </span>
        }
        </>
    )
}
export default Categories;

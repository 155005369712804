import React, { useEffect, useState } from "react";
import "../css/all.css";
import SideBar from "../SideBar";
import { BsSearch } from 'react-icons/bs';
import { RiFileExcel2Line } from 'react-icons/ri';
import Table from "../../component/Table";
import { withRouter } from "react-router-dom";
import Range from "../../component/Range";
import Categories from "../../component/Categories";
import AccountHistory from "../../component/AccountHistory";
import * as api from "../../controller/api";
import Title from "../../component/Title";
import {requestMessageModalOpen,requestSearchAccountPush} from "../../redux/system/system";
import {useDispatch,useSelector} from "react-redux";
import xlsx from "xlsx";

let batchColumns={
  "smartstore":
    [  
      { field: '0', name: '#', match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '1', name: '셀러', match: "accountId", width: 170, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '2', name: '마켓', match: "user_id", width: 150, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '3', name: '주문일',match : "order_date", width: 200, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '4', name: '주문번호',match : "order_no", width: 200, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '5', name: '상품',match: "product_name", width: 200, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '6', name: '수량',match: "qty", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '7', name: '순수익',match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '8', name: '원가',match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '9', name: '수수료',match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '10', name: '매출액',match: "none", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '11', name: '판매금액',match: "total_amt", width: 100, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '12', name: '할인금액',match: "discount_amt", width: 100, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '13', name: '지불방식',match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
    ],
    "coupang":
    [  
      { field: '0', name: '#', match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '1', name: '셀러', match: "accountId", width: 170, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '2', name: '마켓', match: "vendor_id", width: 150, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '3', name: '주문일',match : "ordered_at", width: 200, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '4', name: '주문번호',match : "order_id", width: 200, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '5', name: '상품',match: "null", width: 200, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '6', name: '수량',match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '7', name: '순수익',match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '8', name: '원가',match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '9', name: '수수료',match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '10', name: '매출액',match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '11', name: '판매금액',match: "null", width: 100, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '12', name: '할인금액',match: "null", width: 100, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '13', name: '지불방식',match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
    ],
    "esmplus":
    [  
      { field: '0', name: '#', match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '1', name: '셀러', match: "accountId", width: 170, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '2', name: '마켓', match: "seller_id", width: 150, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '3', name: '주문일',match : "order_at", width: 200, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '4', name: '주문번호',match : "order_no", width: 200, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '5', name: '상품',match: "product_name", width: 200, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '6', name: '수량',match: "qty", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '7', name: '순수익',match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '8', name: '원가',match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '9', name: '수수료',match: "service_fee", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '10', name: '매출액',match: "none", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '11', name: '판매금액',match: "sale_price", width: 100, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '12', name: '할인금액',match: "null", width: 100, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
      { field: '13', name: '지불방식',match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
    ]
}

function Sales(props) {
  let { system } = useSelector(state=>state);
  let dispatch=useDispatch();
  let [record, setRecord] = useState(32); // 검색 결과 갯수;
  let [selectedCategory,setSelectedCategory]=useState("전체");  //선택된 카테고리
  let [selectedCompany,setSelectedCompnay]=useState(null);
  let [selectedRange,setSelectedRange]=useState(null);  //선택한 범위(버튼 on off 용도)
  let [startRange,setStartRange]=useState(""); //실제 범위(time type:string ex: YYYY-MM-DD) 이날 00시부터
  let [endRange,setEndRange]=useState("")  //실제 범위(time type:string ex: YYYY-MM-DD)  이날 24시까지
  let [tableData,setTableDate]=useState({});  //page,count,filter 3가지(변경되면 바로 쿼리 쏴줘야함)!!!!!
  let [accountId,setAccountId]=useState("");
  let [columns,setColumns]=useState([]);
  // { field: '10', name: '지불방식', width: 100, isSort:false, sortDirection: "none" ,isCheckbox: true ,checkboxList: ["카드","현금","간편결제"], checkboxData: new Array(3).fill(true) }
  let [rows,setRows]=useState([null]);
  let [isNeedToSearch,setIsNeedToSearch]=useState(true);

  useEffect(()=>{
    if(isNeedToSearch==false){
      sendApi();
    }
  },[tableData])

  useEffect(()=>{
    setRows([null])
    switch(selectedCompany){
      case "auction":
      case "gmarket":
        setColumns(batchColumns["esmplus"]);
        break;      
      default :
        setColumns(batchColumns[selectedCompany]);
        break;
    }
  },[selectedCompany]);

  const handelXls=()=>{
    if(rows[0]){
      //2차월 배열로 가공
      let arrayXlsx=[columns.map(c=>c.name)].concat(rows);

      //xlsx 제작
      const s2ab=(s)=>{ 
        let buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        let view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;    
      }
      let workbook=xlsx.utils.book_new();
      let sheet=xlsx.utils.aoa_to_sheet(arrayXlsx);  //xlsx 데이터
      xlsx.utils.book_append_sheet(workbook,sheet,"sheet_name");   //sheet name 지정
      let wbout=xlsx.write(workbook,{bookType: "xlsx",type: "binary"});
      let result=new Blob([s2ab(wbout)],{type:"application/octet-stream"}); //xlsx 파일 출력물

      //다운로드
      var xlsxUrl = window.URL.createObjectURL(result);
      let tempLink = document.createElement('a');
      tempLink.href = xlsxUrl;
      tempLink.setAttribute('download', `sellerBox_${accountId}_${selectedCompany}_${selectedCategory}_(${startRange[0]}~${endRange[1]}).xlsx`);
      tempLink.click();
    }else{
      dispatch(requestMessageModalOpen({type: "error",message: "다운로드는 조회결과가 있어야 가능합니다."}))
    }
  }

  const sendApi=()=>{
    api.getSales({
      selectedCategory,
      selectedCompany,
      range: {
        startRange,
        endRange
      },
      tableData,
      accountId
    })
    .then(res=>{
      if(res.data.data==null || res.data.data.length==0){
        dispatch(requestMessageModalOpen({type:"success",message:`매출 내역이 없습니다.`}));
        dispatch(requestSearchAccountPush(accountId));
        console.log(accountId);
      }
      switch (selectedCompany){ //데이터 가공
        case "smartstore":
          setRows(res.data.data.map((d,di)=>{return [
            tableData.page*tableData.count+di,
            d.accountId,
            d.user_id,
            d.order_date.split("T")[0],
            d.order_no,
            d.product_name,
            d.qty,
            null,
            null,
            null,
            d.total_amt-d.discount_amt,
            d.total_amt,
            d.discount_amt,
            null
          ]}));
          break;

        case "coupang":
          setRows(res.data.data.map((d,di)=>{return [
            tableData.page*tableData.count+di,
            d.accountId,
            d.vendor_id,
            d.ordered_at.split("T")[0],
            d.order_id,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null
          ]}));
          break;

        case "auction":
        case "gmarket":
          setRows(res.data.data.map((d,di)=>{return [
            tableData.page*tableData.count+di,
            d.accountId,
            d.seller_id,
            d.order_at.split("T")[0],
            d.order_no,
            d.product_name,
            d.qty,
            null,
            null,
            d.service_fee,
            null,
            d.sale_price,
            null,
            null,
          ]}));
          break;
      }
    })
    .catch(err=>{
      dispatch(requestMessageModalOpen({type:"error",message:err.response.data.message}));
    })
  }

  return (
    <div className="frame">
      <SideBar />
      <div id="mainContentsContainer">
        <Title main={"매출"} sub={"서비스 ▶︎ 매출"} />

        <div className="subContentsContainer">

            <Categories target={"sales"} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} selectedCompany={selectedCompany} setSelectedCompnay={setSelectedCompnay} isNeedToSearch={isNeedToSearch} setIsNeedToSearch={setIsNeedToSearch} isNeedCompany={true}/>
            <Range selectedRange={selectedRange} setSelectedRange={setSelectedRange} startRange={startRange} setStartRange={setStartRange} endRange={endRange} setEndRange={setEndRange}/>

            <div className="option4Container">
              <input type="text" placeholder="셀러 계정" value={accountId} 
                onKeyPress={(e)=>{
                  if(e.key=="Enter"){
                    sendApi();
                    setIsNeedToSearch(false);
                  } 
                }}
                onChange={(e)=>{
                  setAccountId(e.target.value)
                }}
              /><span onClick={()=>{sendApi();setIsNeedToSearch(false);}} className="searchIconBox"><BsSearch /></span>
            </div>

            <AccountHistory setAccountId={setAccountId}/>

            <div className="excelResultContainer"><span className="result">Result of # {record}</span><span className="excel"><RiFileExcel2Line /><span onClick={handelXls}>XLS 내려받기</span></span></div>

        </div>
        <Table  isCheckRow={false} head={[columns,setColumns]} body={[rows,setRows]} control={[tableData,setTableDate]} selectedCompany={selectedCompany} isNeedToSearch={isNeedToSearch} setIsNeedToSearch={setIsNeedToSearch} />
      </div>
    </div >
  );
}

export default withRouter(Sales);
import React, { useEffect, useState } from "react";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ViewListIcon from '@mui/icons-material/ViewList';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import ScrollContainer from 'react-indiana-drag-scroll'

const Table = ({checkedList,isCheckRow,head,body,control,selectedCompany,setIsNeedToSearch,isNeedToSearch,isAccordion,rowDetail,submitHandle,deleteHandle, isNeedBox, firstCheckFilter})=>{
    
//dataHeader ex => [ {field:~~ , name: name, sort:false ,checkbox: false , checkboxData=[]}  ]
    // const [pageInput,setPageInput]=useState("");
    const [selectedRow,setSelectedRow]=useState(null);
    const [page,setPage]=useState(0);  //요청
    const [count,setCount]=useState(15);  //요청
    const [isAllChecked,setIsAllChecked]=useState(false);  //요청 X (삭제할거 뭔지 추가하는 query 필요)
    const [isChecked,setIsChecked]=useState(new Array(count).fill(false)); //요청 X (삭제할거 뭔지 추가하는 query 필요)
    const [clickedCheckboxIndex,setClickedCheckboxIndex]=useState(null); //요청   => 나중에 계정에 사용할때 처리(아직 필요없음)
    const [filter,setFilter]=useState({sortFilter:null,checkFilter:firstCheckFilter ? firstCheckFilter : []}) //테이블 필터 
    const [rowData,setRowData]=useState(body[0][0]!==null ? body[0]?.length<15 ? body[0].concat(new Array(15-body[0]?.length).fill(new Array(head[0]?.length).fill(null))) : body[0] : new Array(15).fill(new Array(head[0]?.length).fill(null))); //그냥 데이터

    useEffect(()=>{
        if(isCheckRow==true){
            checkedList[1]([...isChecked]);
        }
    },[isChecked])

    useEffect(()=>{ 
        if(head[0]!==undefined && head[0].length>1){
            setRowData(body[0][0]!==null ? body[0]?.length<count ? body[0].concat(new Array(count-body[0]?.length).fill(new Array(head[0]?.length).fill(null))) : body[0] : new Array(15).fill(new Array(head[0]?.length).fill(null)))
        }
    },[head[0]])

    useEffect(()=>{
            setIsNeedToSearch(true);
            setPage(0);
            setCount(15);
            setIsAllChecked(false);
            setIsChecked(new Array(15).fill(false));
            setClickedCheckboxIndex(null);
            setFilter({sortFilter:null,checkFilter:firstCheckFilter ? firstCheckFilter : []});
            setRowData(new Array(15).fill(new Array(head[0]?.length).fill(null)))
            setSelectedRow(null);
            // setRowData(body[0]?.length<15 ? body[0].concat(new Array(15-body[0]?.length).fill(new Array(head[0]?.length).fill(null))) : body[0] )
    },[selectedCompany])

    useEffect(()=>{  //필터 (asc,desc, menu => 테이블 헤더 필터)
            setSelectedRow(null);
            control[1]({
                page,
                count,
                filter
            });
    },[page,count,filter]);

    useEffect(()=>{
            setIsChecked(new Array(count).fill(false));
            setRowData(body[0][0]!==null ? body[0]?.length<count ? body[0].concat(new Array(count-body[0]?.length).fill(new Array(head[0]?.length).fill(null))) : body[0] : new Array(15).fill(new Array(head[0]?.length).fill(null)))
    },[count])


    useEffect(()=>{
        setIsChecked(new Array(count).fill(false));
        setSelectedRow(null);
        setRowData(body[0][0]!==null ? body[0]?.length<count ? body[0].concat(new Array(count-body[0]?.length).fill(new Array(head[0]?.length).fill(null))) : body[0] : new Array(15).fill(new Array(head[0]?.length).fill(null)))
    },[body[0]]);

    const handleSelect=(index)=>{
        if(selectedRow==index){
            setSelectedRow(null);
        }else{
            setSelectedRow(index);
        }
    }

    const changeSort=(index)=>{
        let newHead=head[0].map(h=>{
            return {...h,sortDirection: "none"};
        });
        if(head[0][index].sortDirection=="none"){
            newHead[index].sortDirection="desc";
        }else if(head[0][index].sortDirection=="desc"){
            newHead[index].sortDirection="asc";
        }else{
            newHead[index].sortDirection="desc";
        }
        let sortFilter=newHead.find(h=>h.sortDirection!=="none");
        if(sortFilter){
            sortFilter={column:sortFilter.match, direction: sortFilter.sortDirection};
        }else{
            sortFilter=null
        }
        setFilter({
            ...filter,
            sortFilter
        })
        head[1](newHead);
    }

    const changeCheckbox=(headIndex,itemIndex)=>{
        let newHead=head[0].map(h=>{
            return {...h};
        });
        if(newHead[headIndex].checkboxData[itemIndex]==false){
            newHead[headIndex].checkboxData[itemIndex]=true;
        }else{
            newHead[headIndex].checkboxData[itemIndex]=false;
        }
        head[1](newHead);
        console.log(newHead)
        let checkFilter=newHead.map(h=>{
            let isAll=false;
            let rawDummy=h.checkboxList.filter((d,di)=>
                h.checkboxData[di]==true
            );
                let result={
                type: h.match,
                data: rawDummy.map(d=>{
                    let tmp;
                    switch(d) {
                        case "all":
                              tmp="0004";
                              isAll=true;
                        break;
                        case "ios":
                              tmp="0006";
                        break;
                        case "android":
                            tmp="0005";
                        break;
                    }
                    return tmp;
                })
            }
            if(isAll){
                result.data.push("0001");
            }
            return result
        }).filter(d=>d.data.length!==0);

        setFilter({
            ...filter,
            checkFilter
        })
    }
console.log(filter)
    // const handlePage=(e)=>{
    //     let number_exp= /[^0-9]/g;
    //     console.log(e.target.value)
    //     console.log(!number_exp.test(e.target.value));
    //     console.log(pageInput)
    //     if(!number_exp.test(e.target.value)){
    //         setPageInput(e.target.value);
    //     }
    // }

    return (
        <div style={{ maxWidth: '100%',overflowX: "scroll" }}>
            {
            (head[0]==null || body[0]==null)
            ? 
                <Box sx={{ display: 'flex',width: "100%",justifyContent: "center", alignItems: 'center',height: "400px" }}>
                <CircularProgress />
                </Box>
            :
            <>
            {
                clickedCheckboxIndex!==null &&
                <div 
                    onClick={()=>{
                        setClickedCheckboxIndex(null);
                    }}
                    style={{position: "fixed",width: "300000px",height: "300000px",zIndex:5,top: "-100000px",right: "-100000px"}}
                >
                </div>
            }
            <ScrollContainer>
            <table>
                <thead>
                    {
                            isCheckRow && <td>
                                <div style={{
                                    boxSizing: "border-box",
                                    width: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <Checkbox size="small" checked={isAllChecked} style={{width: "18px",height: "18px"}} onChange={(e)=>{
                                        if(isAllChecked){
                                            setIsAllChecked(false);
                                            setIsChecked(new Array(count).fill(false));
                                        }else{
                                            setIsAllChecked(true);
                                            setIsChecked(new Array(count).fill(true));
                                        }
                                    }} />
                                </div>
                            </td>
                    }
                    {
                        head[0].map((data,dataIndex)=>
                        <>
                        <td>
                            <div
                                onClick={()=>{
                                    if(data.isSort==true){
                                        changeSort(dataIndex);
                                    }
                                    if(data.isCheckbox){
                                        setClickedCheckboxIndex(dataIndex);
                                    }
                                    
                                }}
                                style={{
                                    boxSizing: "border-box",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: head[0][dataIndex].width,
                                    height: "30px",
                                    cursor: "pointer"
                                }}
                            >
                            <span style={{zIndex:2}}>{data.name}</span>
                            <span>{data.isSort && (data.sortDirection=="desc" ? <ExpandLessIcon /> : data.sortDirection=="asc" ? <ExpandMoreIcon /> : <UnfoldMoreIcon fontSize="30px" />)}</span>
                            <span>{data.isCheckbox && <ViewListIcon />}</span>
                            </div>
                        </td>
                        </>
                        )
                    }
                </thead>
                {    
                    head[0].map((data,dataIndex)=>(
                                data.isCheckbox && clickedCheckboxIndex==dataIndex &&
                                <tr
                                    style={{height: "30px",overflow: "visible",position: "absolute",padding: "0px",zIndex: 10+dataIndex,}}
                                >
                                    <div
                                    style={{
                                        width: "auto",
                                        position: "relative",
                                        height: `${data.checkboxList.length*30}px`,
                                        border: "2px solid black",
                                        backgroundColor: "#FFFFFF",
                                        padding:"4px",
                                        left: `${head[0].reduce((acc,cur,curIndex)=>{
                                            if(curIndex<dataIndex)return acc+cur.width;
                                            else return acc;
                                            },0)+5+(isCheckRow ? 20 : 0)}px`,
                                        top: "0px",
                                    }}
                                    >
                                        {
                                            data.checkboxList.map((checkbox,checkboxIndex)=>
                                            <div
                                                onClick={()=>changeCheckbox(dataIndex,checkboxIndex)}
                                                style={{cursor: "pointer",height: "30px",display: "flex",justifyContent: "center", alignItems: "center",backgroundColor: head[0][dataIndex].checkboxData[checkboxIndex] ? "blue" : "#FFFFFF"}}
                                            >
                                                {checkbox}
                                            </div>)
                                        }
                                    </div>
                                </tr>
                            ))
                }
                {
                    rowData[0]!==null && 
                    rowData.map((row,rowIndex)=>
                    <>
                    <tr
                        style={{
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black",
                        }}
                    >
                        {
                            isCheckRow && <td
                                style={{
                                    boxSizing: "border-box",
                                    overflowY: "scroll",
                                    verticalAlign: "middle"
                                }}>
                                <div style={{
                                    width: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                    {
                                        row[0]!==null && <Checkbox size="small" style={{width: "18px", height: "18px"}} checked={isChecked[rowIndex]} onChange={(e)=>{
                                            let tmp=[...isChecked];
                                            if(isChecked[rowIndex]){
                                                tmp[rowIndex]=false;
                                            }else{
                                                tmp[rowIndex]=true;
                                            }
                                            setIsChecked(tmp);
                                        }} />
                                    }
                                </div>
                            </td>
                        }
                        {
                            row.map((data,dataIndex)=>
                            <td
                            onClick={()=>{
                                        if(row.length-1==dataIndex && isAccordion!==undefined){
                                            handleSelect(rowIndex);
                                        }
                                    }}
                                    style={{
                                        boxSizing: "border-box",
                                        overflowY: "scroll",
                                        verticalAlign: "middle"
                                    }}
                                >
                                    <div
                                        style={
                                            row.length-1==dataIndex && isAccordion!==undefined
                                            ?
                                                {
                                                    fontSize: "30px",
                                                    cursor: "pointer",
                                                    width: head[0][dataIndex].width,
                                                    overflowY: "scroll",
                                                    height: "40px",
                                                    display: "flex",
                                                    flexWrap: "nowrap",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    paddingTop: "10px",
                                                    paddingBottom: "10px"
                                                }
                                            :
                                            {
                                                width: head[0][dataIndex].width,
                                                overflowY: "scroll",
                                                height: "40px",
                                                display: "flex",
                                                flexWrap: "nowrap",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                paddingTop: "10px",
                                                paddingBottom: "10px"
                                            }
                                        }
                                    >
                                        
                                    {data!==null && (row.length-1==dataIndex && isAccordion!==undefined ? rowIndex==selectedRow ? "-" : "+" : data)}
                                    </div>
                                </td>
                            )
                        }
                    </tr>
                    {
                        row[2]!==null  && selectedRow==rowIndex && (
                            isAccordion=="inquiry" ?
                            <tr
                            style={{height: "140px"}}
                            >
                                <td colSpan={`${head[0].length+(isCheckRow ? 1 : 0)}`} style={{boxSizing: "border-box",border: "4px solid #CBCBCB",width: `${head[0].reduce((acc,cur)=>parseInt(cur.width)+acc,0)+(isCheckRow ? 21 : 0)}px`}}>
                                    <div style={{display: "flex", width: `${head[0].reduce((acc,cur)=>parseInt(cur.width)+acc,0)+(isCheckRow ? 20 : 0)}px`, alignItems: "center",height: "40px" ,borderBottom: "4px solid #CBCBCB",backgroundColor: "#F0F0F0"}}>
                                        <div style={{display: "flex", width: "50%", justifyContent: "flex-start", alignItems: "center"}}>
                                            <span style={{padding: "3px 16px 3px 16px", margin: "6px", textAlign: "center", verticalAlign: "middle", fontWeight: 600}}>   
                                            {rowDetail[0][rowIndex].title}
                                            </span>
                                        </div>
                                        <div style={{display: "flex", width: "50%", justifyContent: "flex-end", alignItems: "center"}}>
                                            <span style={{padding: "3px 16px 3px 16px", margin: "6px", border: "2px solid black", textAlign: "center", verticalAlign: "middle",fontWeight: 550,color: "#FFFFFF", backgroundColor: "#000000"}}
                                            onClick={()=>{
                                                setSelectedRow(null);
                                                submitHandle({
                                                    context: rowDetail[0][rowIndex].context,
                                                    email: rowDetail[0][rowIndex].email,
                                                    answer: document.getElementById(`${rowIndex}+textArea`).value,
                                                    seq_no: rowDetail[0][rowIndex].seq_no
                                                });
                                            }}
                                            >
                                                {"답변하기"}
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{display: "flex", width: `${head[0].reduce((acc,cur,idx)=>parseInt(cur.width)+acc,0)+(isCheckRow ? 20 : 0)}px`, alignItems: "center"}}>
                                            <textarea 
                                                className="noCssTextarea"
                                                disabled={true}
                                                style={{width: "50%", height: "200px",border: "4px solid #CBCBCB",padding: "5px"}}
                                                defaultValue={rowDetail[0][rowIndex].context} 
                                            />
                                            <textarea 
                                                id={`${rowIndex}+textArea`}
                                                className="noCssTextarea"
                                                style={{width: "50%", height: "200px",border: "4px solid #CBCBCB",padding: "5px"}}
                                                onClick={(e)=>{ document.getElementById(`${rowIndex}+textArea`).focus();}}
                                                defaultValue={rowDetail[0][rowIndex].answer==null ? "" : rowDetail[0][rowIndex].answer} 
                                            />
                                    </div>
                                </td>
                            </tr>
                            :
                            isAccordion=="notice" ?
                            <tr
                            style={{height: "140px"}}
                            >
                                <td colSpan={`${head[0].length+(isCheckRow ? 1 : 0)}`} style={{boxSizing: "border-box",border: "4px solid #CBCBCB",width: `${head[0].reduce((acc,cur)=>parseInt(cur.width)+acc,0)+(isCheckRow ? 21 : 0)}px`}}>
                                    <div style={{display: "flex", width: `${head[0].reduce((acc,cur)=>parseInt(cur.width)+acc,0)+(isCheckRow ? 21 : 0)}px`, alignItems: "center",height: "40px" ,borderBottom: "4px solid #CBCBCB",backgroundColor: "#F0F0F0"}}>
                                        <div style={{display: "flex", width: "50%", justifyContent: "flex-start", alignItems: "center"}}>
                                            <span style={{width: "100%", padding: "3px 16px 3px 16px", margin: "6px", textAlign: "center", verticalAlign: "middle", fontWeight: 600}}>   
                                                {rowDetail[0][rowIndex] ?
                                                    <input
                                                    type="text" 
                                                    id={`${rowIndex}+input`}
                                                    className="noCssInput"
                                                    style={{width: "100%"}}
                                                    onClick={(e)=>{ document.getElementById(`${rowIndex}+input`).focus();}}
                                                    defaultValue={rowDetail[0][rowIndex].title} 
                                                    />
                                                    :
                                                    null
                                                }
                                            </span>
                                        </div>
                                        <div style={{display: "flex", width: "50%", justifyContent: "flex-end", alignItems: "center"}}>
                                              <label for={rowIndex+"+display+y"}>화면표시YES</label>
                                              <input type="radio" id={rowIndex+"+display+y"} name="fav_language" value="HTML" checked />
                                              <label for={rowIndex+"+display+n"}>화면표시NO</label>
                                              <input type="radio" id={rowIndex+"+display+n"} name="fav_language" value="CSS" />
                                            <span 
                                                style={{padding: "3px 16px 3px 16px", margin: "6px", border: "2px solid black", textAlign: "center", verticalAlign: "middle",fontWeight: 550,color: "#FFFFFF", backgroundColor: "#000000", cursor: "pointer"}}
                                                onClick={()=>{
                                                    setSelectedRow(null);
                                                    submitHandle({
                                                        title:document.getElementById(`${rowIndex}+input`).value,
                                                        context: document.getElementById(`${rowIndex}+textArea`).value,
                                                        display_yn: document.getElementById(rowIndex+"+display+y").checked==true ? "Y" : "N",
                                                        seq_no: rowDetail[0][rowIndex].seq_no
                                                    });
                                                }}
                                            >
                                                {"수정하기"}
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{display: "flex", width: "100%", justifyContent: "flex-start", alignItems: "center"}}>
                                        <span style={{width: "100%",padding: "3px 16px 3px 16px", margin: "6px", textAlign: "center", verticalAlign: "middle", fontWeight: 600}}>   
                                            {rowDetail[0][rowIndex] ?
                                            <textarea 
                                                id={`${rowIndex}+textArea`}
                                                className="noCssTextarea"
                                                style={{width: "100%", height: "90px"}}
                                                onClick={(e)=>{ document.getElementById(`${rowIndex}+textArea`).focus();}}
                                                defaultValue={rowDetail[0][rowIndex].context} 
                                            />
                                                :
                                                null
                                            }
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            :
                            null
                        )
                    }
                    </>
                    )
                }
            </table>
            </ScrollContainer>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width :"100%",
                    paddingTop: "5px",
                }}>
                    <div>
                {"Result of # 962 "}
                {/* <span> */}
                <select onClick={(e)=>console.log(e.target.value)} onChange={(e)=>{setCount(parseInt(e.target.value))}}>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                {/* </span> */}
                {" 개씩보기"}
                </div>
                {/* <div>
                    <input value={pageInput} type="text" placeholder="직접 입력" onChange={handlePage}/>
                </div> */}
                <Stack spacing={2}>
                    <Pagination count={10000000} defaultPage={6} siblingCount={0} 
                        page={page+1}
                        onChange={(e,newPage)=>{
                            setPage(newPage-1);
                        }} 
                    />
                </Stack>
                </div>
            </>
            }
        </div>
    );
}
export default Table;
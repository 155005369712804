export const initialState = {
    os: null,
    browser: null,
    local: null,
    language: "ko",
    isMessageModalOpen: false,
    messageModalType: "success",
    messageModalMessage: "",
    searchAccountList: []
};

export const REQUEST_MESSAGE_MODAL_OPEN="REQUEST_MESSAGE_MODAL_OPEN";
export const REQUEST_MESSAGE_MODAL_CLOSE="REQUEST_MESSAGE_MODAL_CLOSE";
export const REQUEST_SEARCH_ACCOUNT_PUSH="REQUEST_SEARCH_ACCOUNT_PUSH";
export const REQUEST_SEARCH_ACCOUNT_DELETE="REQUEST_SEARCH_ACCOUNT_DELETE";

export const requestMessageModalOpen=(data)=>({
    type: REQUEST_MESSAGE_MODAL_OPEN,
    data: data
});

export const requestMessageModalClose=(data)=>({
    type: REQUEST_MESSAGE_MODAL_CLOSE,
    data: data
});

export const requestSearchAccountPush=(data)=>({
    type: REQUEST_SEARCH_ACCOUNT_PUSH,
    data: data
});

export const requestSearchAccountDelete=(data)=>({
    type: REQUEST_SEARCH_ACCOUNT_DELETE,
    data: data
});

const system=(state=initialState,action)=>{
    switch(action.type){
        case "REQUEST_MESSAGE_MODAL_OPEN":
            return {
                ...state,
                isMessageModalOpen: true,
                messageModalType: action.data.type,
                messageModalMessage: action.data.message
            }
            break;
        case "REQUEST_MESSAGE_MODAL_CLOSE":
            return {
                ...state,
                isMessageModalOpen: false
            }
            break;
        case "REQUEST_SEARCH_ACCOUNT_PUSH":
            let tmpAccountPushList=state.searchAccountList;
            if(tmpAccountPushList.indexOf(action.data)==-1 && action.data!==""){
                if(tmpAccountPushList.length>4){
                    tmpAccountPushList.pop();
                }
                tmpAccountPushList.unshift(action.data);
            }
            return {
                ...state,
                searchAccountList:tmpAccountPushList 
            }
            break;
        case "REQUEST_SEARCH_ACCOUNT_DELETE":
            let tmpAccountDeleteList=state.searchAccountList;
            if(action.data<=tmpAccountDeleteList.length){
                tmpAccountDeleteList.splice(action.data,1);
                return {
                    ...state,
                    searchAccountList:tmpAccountDeleteList
                }
            }
            break;
        default:
            return state;
            break;
    }
}
export default system;
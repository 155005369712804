import React, { useEffect, useState } from "react";
import "../css/all.css";
import SideBar from "../SideBar";
import { BsSearch } from 'react-icons/bs';
import { RiFileExcel2Line } from 'react-icons/ri';
import Table from "../../component/Table";
import { withRouter } from "react-router-dom";
import Range from "../../component/Range";
import Categories from "../../component/Categories";
import AccountHistory from "../../component/AccountHistory";
import * as api from "../../controller/api";
import Title from "../../component/Title";
import {requestMessageModalOpen,requestSearchAccountPush} from "../../redux/system/system";
import {useDispatch,useSelector} from "react-redux";

function PushNotificationHistory(props) {
  let { system } = useSelector(state=>state);
  let dispatch=useDispatch();
  let [record, setRecord] = useState(32); // 검색 결과 갯수;
  let [selectedCategory,setSelectedCategory]=useState(0);  //선택된 카테고리
  let [selectedCompany,setSelectedCompnay]=useState(null);
  let [selectedRange,setSelectedRange]=useState(null);  //선택한 범위(버튼 on off 용도)
  let [startRange,setStartRange]=useState(""); //실제 범위(time type:string ex: YYYY-MM-DD) 이날 00시부터
  let [endRange,setEndRange]=useState("")  //실제 범위(time type:string ex: YYYY-MM-DD)  이날 24시까지
  let [tableData,setTableDate]=useState({});  //page,count,filter 3가지(변경되면 바로 쿼리 쏴줘야함)!!!!!
  let [accountId,setAccountId]=useState("");
  let [columns,setColumns]=useState(
    [  
        { field: '0', name: '#', match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [],isAccordion:false  },
        { field: '1', name: '대상자', match: "target_id", width: 220, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [],isAccordion:false  },
        { field: '2', name: '발송일', match: "reserve_dt", width: 170, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [],isAccordion:false  },
        { field: '3', name: '제목', match: "title", width: 170, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [],isAccordion:false  },
        { field: '4', name: '내용',match : "context", width: 200, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [],isAccordion:false  },
        { field: '5', name: '작성일', match: "reg_dt", width: 170, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [],isAccordion:false  },
        { field: '6', name: '상태',match: "sent_yn", width: 120, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [],isAccordion:false  },
        { field: '7', name: 'OS',match: "report_cd", width: 120, isSort:false, sortDirection: "none" ,isCheckbox: true ,checkboxList: ["all","ios","android"], checkboxData: [true,true,true],isAccordion:false  },
      ]
  );
  let [rows,setRows]=useState([null]);
    let [rowDetail,setRowDetail]=useState(null);
  let [isNeedToSearch,setIsNeedToSearch]=useState(true);

  useEffect(()=>{
    if(isNeedToSearch==false){
      sendApi();
    }
  },[tableData])

  const sendApi=()=>{
    api.getPushHistory({
      selectedCategory,
      range: {
        startRange,
        endRange
      },
      tableData,
      accountId
    })
    .then(res=>{
      if(res.data.data==null || res.data.data.length==0){
        dispatch(requestMessageModalOpen({type:"success",message:`문의사항 내역이 없습니다.`}));
        dispatch(requestSearchAccountPush(accountId));
      }
          setRows(res.data.data.map((d,di)=>{return [
            tableData.page*tableData.count+di,
            d.report_cd=="0001" ? "전체" : d.tbl_user.email,
            d.reserve_dt.split("T")[0],
            d.title,
            d.context,
            d.reg_dt.split("T")[0],
            d.sent_yn=="Y" ? "전송완료" : "미전송",
            (d.report_cd=="0001" || d.report_cd=="0004") ? "ALL" : d.report_cd=="0006" ? "ios" : "android" ,
          ]}));
        //   setRowDetail(
        //     res.data.data.map((d,di)=>{return {
        //         answer: d.tbl_user.email,
        //         email: d.email,
        //         title:d.title,
        //         context:d.context,
        //         seq_no: d.seq_no,
        //         user_no:d.user_no
        //     }})
        //   );
      
    })
    .catch(err=>{
      dispatch(requestMessageModalOpen({type:"error",message:err.response?.data?.message}));
    })
  }

//   const changeInquiry=(data)=>{
//     api.changeInquiry(data)
//     .then(res=>{
//         dispatch(requestMessageModalOpen({type:"success",message: "답변달기 성공"}));
//         sendApi();
//     })
//     .catch(err=>{
//         dispatch(requestMessageModalOpen({type:"error",message:err.response?.data?.message}));
//     });
//   }

  return (
    <div className="frame">
      <SideBar />
      <div id="mainContentsContainer">
        <Title main={"발송 이력"} sub={"푸시메시지 ▶︎ 발송이력"} />

        <div className="subContentsContainer">

            <Categories target={"pushHistory"} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} selectedCompany={null} setSelectedCompnay={null} isNeedToSearch={isNeedToSearch} setIsNeedToSearch={setIsNeedToSearch} isNeedBox={true}/>
            <Range selectedRange={selectedRange} setSelectedRange={setSelectedRange} startRange={startRange} setStartRange={setStartRange} endRange={endRange} setEndRange={setEndRange} defaultRange={"day"}/>

            <div className="option4Container">
              <input type="text" placeholder={
                    selectedCategory=="context" ? "내용" 
                    : selectedCategory=="title" ? "제목"
                    : selectedCategory=="reg_id" ? "작성자"
                    : selectedCategory=="target_id" ? "대상자"
                    : null
                } value={accountId} 
                onKeyPress={(e)=>{
                  if(e.key=="Enter"){
                    sendApi();
                    setIsNeedToSearch(false);
                  } 
                }}
                onChange={(e)=>{
                  setAccountId(e.target.value)
                }}
              /><span onClick={()=>{sendApi();setIsNeedToSearch(false);}} className="searchIconBox"><BsSearch /></span>
            </div>
            <AccountHistory setAccountId={setAccountId}/>
        </div>
        <Table  rowDetail={[rowDetail,rowDetail]} isCheckRow={false} head={[columns,setColumns]} body={[rows,setRows]} control={[tableData,setTableDate]} selectedCompany={selectedCompany} isNeedToSearch={isNeedToSearch} setIsNeedToSearch={setIsNeedToSearch}  firstCheckFilter={[{type: "report_cd", data: ["0006", "0005","0001", "0004"]}]} />
      </div>
    </div >
  );
}

export default withRouter(PushNotificationHistory);
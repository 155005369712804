export const initialState = {
    userName: null,
    userEmail: null,
};

export const REQUEST_USER_SETTING="REQUEST_USER_SETTING";
export const REQUEST_USER_DELETE="REQUEST_USER_DELETE";

export const requestUserSettingAction=(data={})=>({
    type: REQUEST_USER_SETTING,
    data: data
});
export const requestUserDeleteAction=()=>({
    type: REQUEST_USER_DELETE
});

const user=(state=initialState,action)=>{
    switch(action.type){
        case "REQUEST_USER_SETTING":
            return {
                ...state,
                ...action.data
            }
        case "REQUEST_USER_DELETE":
            return {};
        default:
            return state;
    }
}
export default user;
import React from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import './reset.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./views/Login";
import Header from "./views/Header";
import Main from "./views/Main";
//service
import Sales from "./views/serviceComponents/Sales";
import Calculate from "./views/serviceComponents/Calculate";
import Confirm from "./views/serviceComponents/Confirm";
import Delivery from "./views/serviceComponents/Delivery";
import Price from "./views/serviceComponents/Price";
import Refund from "./views/serviceComponents/Refund";
import Review from "./views/serviceComponents/Review";
import Buyer from "./views/serviceComponents/Buyer";
//user
import Inquiry from "./views/userComponents/Inquiry";
import Market from "./views/userComponents/Market";
//general
import Term from "./views/generalComponents/Term";
//pushNotification
import SendPushNotification from "./views/pushNotificationComponents/SendPushNotification";


import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useSelector,useDispatch } from "react-redux";
import {requestMessageModalClose} from "./redux/system/system";
import Notice from "./views/generalComponents/Notice";
import PushNotificationHistory from "./views/pushNotificationComponents/PushNotificationHistory";

function App() {

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const dispatch = useDispatch();
  const { system } = useSelector(state=>state);

  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }
    dispatch(requestMessageModalClose());
  };

  return (
    <BrowserRouter>
      <Stack spacing={2} sx={{ maxWidth: 1000 }}>
        <Snackbar open={system.isMessageModalOpen} autoHideDuration={3000} onClose={handleClose } anchorOrigin={{vertical:"top",horizontal:"center" }} >
          <Alert onClose={handleClose} severity={system.messageModalType} sx={{ width: '100%' }} style={{fontSize: "16px",fontWeight: 600}}>
            {system.messageModalMessage}
          </Alert>
        </Snackbar>
      </Stack>
      <Header />
      <Switch>

        <Route exact path="/sellerbox-admin.cplat.io/login">
          <Login />
        </Route>

        <Route exact path="/">
          <Main />
        </Route>

        <Route exact path="/sellerbox-admin.cplat.io/">
          <Main />
        </Route>

        <Route exact path="/sellerbox-admin.cplat.io/main">
          <Main />
        </Route>

          {/* SERVICE ROUTER  */}

        <Route exact path="/sellerbox-admin.cplat.io/service/sales">
          <Sales />
        </Route>
        <Route exact path="/sellerbox-admin.cplat.io/service/calculate">
          <Calculate />
        </Route>
        <Route exact path="/sellerbox-admin.cplat.io/service/confirm">
          <Confirm />
        </Route>
        <Route exact path="/sellerbox-admin.cplat.io/service/delivery">
          <Delivery />
        </Route>
        <Route exact path="/sellerbox-admin.cplat.io/service/price">
          <Price />
        </Route>
        <Route exact path="/sellerbox-admin.cplat.io/service/refund">
          <Refund />
        </Route>
        <Route exact path="/sellerbox-admin.cplat.io/service/review">
          <Review />
        </Route>
        <Route exact path="/sellerbox-admin.cplat.io/service/buyer">
          <Buyer />
        </Route>


      {/* USER ROUTER  */}

        <Route exact path="/sellerbox-admin.cplat.io/user/inquiry">
          <Inquiry />
        </Route>

        <Route exact path="/sellerbox-admin.cplat.io/user/market">
          <Market />
        </Route>

      {/* GENERAL ROUTER  */}

        <Route exact path="/sellerbox-admin.cplat.io/general/term">
          <Term />
        </Route>
        <Route exact path="/sellerbox-admin.cplat.io/general/notice">
          <Notice />
        </Route>
    
    {/* PUSH NOTIFICATION ROUTER */}
        <Route exact path="/sellerbox-admin.cplat.io/pushNotification/sendPushNotification">
          <SendPushNotification />
        </Route>

        <Route exact path="/sellerbox-admin.cplat.io/pushNotification/pushNotificationHistory">
          <PushNotificationHistory />
        </Route>

      </Switch>
    </BrowserRouter>
  );
}

export default App;

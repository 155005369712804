import React, { useState } from "react";
import "../css/Main.css";

function CurrentUser() {
  // container five 데이터
  let [currentUser, setCurrentUser] = useState("789"); //현재 접속 유저 수


  return (
    <div class="five" style={{ display: "flex", flexDirection: "column" }}>
      <div className="font1">현재 접속자 현황</div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div style={{ marginTop: 80, fontSize: 70, fontWeight: 800 }}>{currentUser}명</div>
      </div>
    </div>
  );
}

export default CurrentUser;
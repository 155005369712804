import React, { useState } from "react";
import "../css/Main.css";

function WithdrawalUser() {
  // container four 데이터,
  let [today, setToday] = useState("0");// 오늘 탈퇴
  let [comWithYester, setComWithYester] = useState("-2");// 어제 대비
  let [thisMonth, setThisMonth] = useState("9");// 이번달
  let [comWithLastMonth, setComWithLastMonth] = useState("+9"); // 지난달 대비 
  let [acculmulated, setAcculmulated] = useState("9"); // 누적 회원

  return (
    <div class="four">
      <div>
        <div className="font1">신규 사용자 현황</div>
        <img src="/img/withdrawalUser.png" />
      </div>
      <div>
        <div className="font2 dataLeftMargin" style={{ marginTop: 60 }}>오늘 {today}</div>
        <div className="font3 dataLeftMargin" >어제대비 {comWithYester}</div>
        <div className="font2 dataLeftMargin" >이번달 {thisMonth}</div>
        <div className="font3 dataLeftMargin" >지난달대비 {comWithLastMonth}</div>
        <div className="font2 dataLeftMargin" >누적회원수 {acculmulated}</div>
      </div>
    </div>
  );
}

export default WithdrawalUser;

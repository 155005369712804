import { createStore } from 'redux';
import { combineReducers } from "redux";
import system from "./system/system";
import user from "./user/user";


let rootReducer=combineReducers({
    system,
    user
});

  const store = createStore(rootReducer);


export default store;
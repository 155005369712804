import React,{useState, useEffect} from "react";
import "../css/all.css";
import SideBar from "../SideBar";
import Title from "../../component/Title";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useMediaQuery } from "@mui/material";
import Modal from "@mui/material/Modal";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import TimePicker from "@mui/lab/TimePicker";
import TextField from "@material-ui/core/TextField";
import EventNoteIcon from '@mui/icons-material/EventNote';
import * as api from "../../controller/api";
import { useDispatch } from "react-redux";
import { requestMessageModalOpen } from "../../redux/system/system";
const SendPushNotification=()=>{
    const dispatch = useDispatch();
    const under_800 = useMediaQuery("(max-width:800px)");
    const under_600 = useMediaQuery("(max-width:600px)");
    const under_400 = useMediaQuery("(max-width:400px)");

    const [isGroup,setIsGroup]=useState(false);
    const [group,setGroup]=useState("all");
    const [target,setTarget]=useState("");
    const [title,setTitle]=useState("");
    const [context,setContext]=useState("");
    const [selectedOs,setSelectedOs]=useState("all");
    const [selectedDate,setSelectedDate]=useState("now");
    const [sendCycle,setSendCycle]=useState("");
    const [isDateModalOpen,setIsDateModalOpen]=useState(false);
    const [sendDate,setSendDate]=useState("");
    const [sendTime,setSendTime]=useState("");
    const [finalTime,setFinalTime]=useState("");
    const [finalTimeString,setFinalTimeString]=useState("");
    const [isApiLoading,setIsApiLoading]=useState(false);
    const handleModalOpen = () => setIsDateModalOpen(true);
    const handleModalClose = () => setIsDateModalOpen(false);

    useEffect(()=>{
        let utc = new Date();
        utc=utc.getTime() + (utc.getTimezoneOffset() * 60 * 1000);
        const kr_offset = 9 * 60 * 60 * 1000;
        const kr_date = new Date(utc + (kr_offset));
        const kr_time_offset_5 = new Date(utc + (kr_offset+1000*60*10));
        setSendDate(kr_date);
        setSendTime(kr_time_offset_5);
    },[])

    useEffect(()=>{
        if(isGroup!==true){
            setSelectedOs("all");
        }
    },[isGroup])

    useEffect(()=>{
        if(sendDate!=="" && sendTime!==""){
            setFinalTime(
                new Date(
                    sendDate.getFullYear(), 
                    sendDate.getMonth(),
                    sendDate.getDate(),
                    sendTime.getHours(),
                    sendTime.getMinutes(),
                    sendTime.getSeconds()
                )
            );
            setFinalTimeString(
                sendDate.getFullYear()+"-"+
                (sendDate.getMonth()+1)+"-"+
                sendDate.getDate()+" "+
                sendTime.getHours()+"-"+
                sendTime.getMinutes()+"-"+
                sendTime.getSeconds()
            );
        }
    },[sendTime,sendDate]);

    const handleCycle=(cycle)=>{
        if(cycle==sendCycle){
            setSendCycle("");
        }else{
            setSendCycle(cycle);
        }
    };

    const sendAPI=()=>{
        if(isApiLoading==false){
            let data={

                selectedOs, //all == 전부, ios==ios android==android 

                isGroup,  //그룹인지 개인인지 판별
                group,  //그룹 네임 ex "all" ect~~ 
                target, //그룹 아니면 seller id로 
                
                title,
                context,

                reserveDate:finalTime //예약 날짜
            }

            let utc = new Date();
            utc=utc.getTime() + (utc.getTimezoneOffset() * 60 * 1000);
            const kr_offset = 9 * 60 * 60 * 1000;
            const kr_date = new Date(utc + (kr_offset));

            let errorName="";
            if(["all","ios","android"].indexOf(selectedOs)==-1){
                errorName="잘못된 OS 입니다.";
            }
            if(isGroup){
                if(group=="" || !group){
                    errorName="잘못된 그룹 입니다.";
                }
            }else{
                if(target=="" || !target){
                    errorName="셀러를 입력한 후에 발송이 가능합니다.";
                }
            }
            if(context==""){
                errorName="내용을 입력한 후에 발송이 가능합니다.";
            }
            if(finalTime==""){
                errorName="전송일을 입력한 후에 발송이 가능합니다.";
            }
            if(title==""){
                errorName="제목을 입력한 후에 발송이 가능합니다.";
            }
            if((finalTime?.getTime()-kr_date.getTime())<(1000*60*10)){
                errorName="발송일은 발송시간 기준 10분 이후로 설정 가능합니다.";
            }
            if(errorName!==""){
                dispatch(requestMessageModalOpen({type: "error",message: errorName}));
            }else{
                setIsApiLoading(true);
                api.sendPushNotification(data)
                .then(res=>{
                    dispatch(requestMessageModalOpen({type: "success",message: `푸시메시지 등록 완료`}));
                    setIsGroup(false);
                    setTarget("");
                    setTitle("");
                    setContext("");
                    setSelectedOs("all");
                })
                .catch(err=>{
                    dispatch(requestMessageModalOpen({type: "error",message: err.response.data.message}));
                })
                .finally(()=>{
                    setIsApiLoading(false);
                });
            }
        }
    }

    return(
        <div className="frame">
            <SideBar />
            <div id="mainContentsContainer">
                <Title main={"메시지 발송"} sub={"푸시메시지 ▶︎ 메시지 발송"} />
                <div className="subContentsContainer">
                    <div style={{display: "flex", width: "100%",alignItems: "flex-start"}}>
                        <div style={{display: "flex", width: under_400 ? "70px" : "80px",justifyContent: "center", alignItems: "center",flexDirection: "column"}}>
                            <div className="pushNoticeBoxLeft">*OS</div>
                            <div className="pushNoticeBoxLeft" style={under_800 ? {height: "80px"} : {height: "40px"}}>셀러 지정</div>
                            <div className="pushNoticeBoxLeft">제목</div>
                            <div className="pushNoticeBoxLeft" style={{alignItems: "flex-start",height: "300px"}}>*내용</div>
                            <div className="pushNoticeBoxLeft">*발송일</div>
                        </div>
                        <div style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center",flexDirection: "column"}}>
                            <div className="pushNoticeBoxRight">
                                <span className="osCategoryBox" onClick={()=>{setSelectedOs("all");}}><span>전체</span><span>{selectedOs=="all" ? <CheckCircleIcon style={{fontSize: "15px"}}/> : <CheckCircleOutlineIcon style={{fontSize: "15px"}}/>}</span></span>
                                <span className="osCategoryBox" onClick={()=>{
                                        if(isGroup==true){
                                            setSelectedOs("ios");
                                        }
                                    }}>
                                <span>IOS</span>{selectedOs=="ios" ? <CheckCircleIcon style={{fontSize: "15px"}}/> : <CheckCircleOutlineIcon style={{fontSize: "15px"}}/>}</span>
                                <span className="osCategoryBox" onClick={()=>{
                                        if(isGroup==true){
                                            setSelectedOs("android");
                                        }
                                    }}
                                ><span>Android</span>{selectedOs=="android" ? <CheckCircleIcon style={{fontSize: "15px"}}/> : <CheckCircleOutlineIcon style={{fontSize: "15px"}}/>}</span>
                            </div>
                            <div className="pushNoticeBoxRight" style={under_800 ? {height: "80px"} : {height: "40px"}}>
                                <div style={under_800 ? {display: "flex",height: "80px", flexDirection: "column", justifyContent: "space-around" } : {display: "flex",height: "40px", flexDirection: "row", alignItems: "center"}}>
                                    <span style={under_800 ? {} : {marginRight: "10px", cursor: "pointer"}} onClick={()=>{setIsGroup(true);}}>
                                        <span>그룹</span>
                                        {
                                            isGroup ?
                                                <span><CheckCircleIcon style={{fontSize: "15px"}}/></span>
                                            :
                                                <span><CheckCircleOutlineIcon style={{fontSize: "15px"}}/></span>
                                        }
                                        <span style={{marginLeft: "4px"}}>
                                            <select onChange={(e)=>{
                                                setGroup(e.target.value);
                                            }}>
                                                <option value="all">전체</option>
                                            </select>
                                        </span>
                                    </span>
                                    <span onClick={()=>{setIsGroup(false);}} style={{cursor: "pointer"}}>
                                        <span>지정</span>
                                        {
                                            isGroup ?
                                                <span><CheckCircleOutlineIcon style={{fontSize: "15px"}}/></span>
                                            :
                                                <span><CheckCircleIcon style={{fontSize: "15px"}}/></span>
                                        }
                                        <span>
                                            <input className="noCssInput" value={target} onChange={(e)=>{setTarget(e.target.value)}} type="text" style={{marginLeft: "4px",border: "1px solid gray",width: under_800 ? under_600 ?  under_400 ? "140px" : "200px" : "300px" : "450px"}}/>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className="pushNoticeBoxRight">
                                <span>
                                    <span>
                                        <input 
                                            value={title}
                                            onChange={(e)=>{setTitle(e.target.value);}}
                                            className="noCssInput" type="text" style={{border: "1px solid gray",width: under_800 ? under_600 ?  under_400 ? "120px" : "180px" : "280px" : "550px"}}/>
                                    </span>
                                    <span>
                                        {`${title.length}/30자`}
                                    </span>
                                </span>
                            </div>
                            <div className="pushNoticeBoxRight" style={{alignItems: "flex-start",minHeight: "300px",paddingRight: "10px"}}>
                                <textarea value={context} onChange={(e)=>{setContext(e.target.value);}} className="noCssTextarea" style={{border: "1px solid gray", borderRadius: "7px",width: "97%",padding: "5px", height: "280px"}} />
                            </div>
                            <div className="pushNoticeBoxRight" style={{flexDirection: "column",alignItems: "flex-start",justifyContent: "center"}}>
                                {/* <span className="osCategoryBox" style={{padding: "10px 0px 5px 4px"}} onClick={()=>{setSelectedDate("now");}}><span>즉시</span><span>{selectedDate=="now" ? <CheckCircleIcon style={{fontSize: "15px"}}/> : <CheckCircleOutlineIcon style={{fontSize: "15px"}}/>}</span></span> */}
                                <span style={{padding: "5px 0px 10px 0px",display: "flex" , alignItems: "center",cursor:"pointer"}} onClick={()=>{setSelectedDate("notNow");handleModalOpen();}}>
                                    <span>{finalTimeString}</span>
                                    <span> <EventNoteIcon style={{fontSize: "16px"}}/></span>
                                    {/* <span>{selectedDate!=="now" ? <CheckCircleIcon style={{fontSize: "15px"}}/> : <CheckCircleOutlineIcon style={{fontSize: "15px"}}/>}</span> */}
                                </span>
                            </div>
                            <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                                <div onClick={sendAPI} className="pushSendButton">
                                        {isApiLoading ? "발송중" : "발송"}
                                </div>
                            </div>
                        </div>
                        <Modal
                            open={isDateModalOpen}
                            // onClose={handleModalClose}
                            style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                        >
                            <div style={{display: "flex",flexDirection: "column",justifyContent: "flex-start" ,width: under_800 ? "70%" : "50%" ,height: "400px",border: "4px solid gray",backgroundColor: "#FFFFFF"}}>
                                <div style={{display: "flex", width: "calc(100%-20px)",alignItems: "center", justifyContent: "flex-start",height: "50px",borderBottom: "4px solid gray",paddingLeft: "20px"}}>발송일</div>
                                {/* <div style={{display: "flex", width: "calc(100% -25px)",alignItems: "center", justifyContent: "flex-start",height: "40px",paddingLeft: "25px"}}>반복</div> */}
                                {/* <div style={{display: "flex", width: "calc(100% - 30px)",alignItems: "center", justifyContent: "flex-start",height: "40px",paddingLeft: "30px"}}>
                                    <span className="osCategoryBox" onClick={()=>{handleCycle("day");}}><span>매일</span><span>{sendCycle=="day" ? <CheckCircleIcon style={{fontSize: "15px"}}/> : <CheckCircleOutlineIcon style={{fontSize: "15px"}}/>}</span></span>
                                    <span className="osCategoryBox" onClick={()=>{handleCycle("week");}}><span>매주</span><span>{sendCycle=="week" ? <CheckCircleIcon style={{fontSize: "15px"}}/> : <CheckCircleOutlineIcon style={{fontSize: "15px"}}/>}</span></span>
                                    <span className="osCategoryBox" onClick={()=>{handleCycle("month");}}><span>매월</span><span>{sendCycle=="month" ? <CheckCircleIcon style={{fontSize: "15px"}}/> : <CheckCircleOutlineIcon style={{fontSize: "15px"}}/>}</span></span>
                                </div> */}
                                <div style={{display: "flex", width: "100%",alignItems: under_600 ? "flex-start" : "center", justifyContent: "center", flexWrap: "wrap"}}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        {
                                            under_600
                                            ?
                                                <div style={{display: "flex", width: "100%",alignItems: "center", justifyContent: "center"}}>
                                                    <MobileDatePicker
                                                        label="날짜"
                                                        inputFormat="MM/dd/yyyy"
                                                        value={sendDate}
                                                        onChange={(newDate)=>{setSendDate(newDate)}}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </div>
                                            :
                                                <div style={{display: "flex", width: "48%",alignItems: "center", justifyContent: "center"}}>
                                                    <DesktopDatePicker 
                                                        label="날짜"
                                                        inputFormat="MM/dd/yyyy"
                                                        value={sendDate}
                                                        onChange={(newDate)=>{setSendDate(newDate)}}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </div>
                                        }
                                        <div style={{display: "flex", width: under_600 ?  "100%" :  "48%",alignItems: "center", justifyContent: "center"}}>
                                            <TimePicker
                                                label="시간"
                                                value={sendTime}
                                                onChange={(newTime)=>{setSendTime(newTime);}}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </div>
                                        <div style={{display: "flex", width: "100%",alignItems: "center", justifyContent: "flex-end",padding: "20px 20px 0px 0px"}}>
                                            <div
                                                onClick={handleModalClose}
                                                style={{display: "flex", alignItems: "center", justifyContent: "center",padding: "4px 10px 4px 10px", borderRadius: "7px",border: "2px solid gray",cursor: "pointer"}}>
                                                {"확인"}
                                            </div>
                                        </div>
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SendPushNotification;
import React, {useState,useEffect} from "react";
import "../css/all.css";
import Title from "../../component/Title";
import SideBar from "../SideBar";
import TermBox from "../../component/TermBox";
const Term = ()=>{
    const [terms,setTerms]=useState([
        {title:"",detail:"",isNeed:true},
    ]);
    
    const appendTerm=()=>{
        let tmp=[...terms];
        tmp.push({title:"", detail: "",isNeed:false});
        setTerms(tmp);
    }

    return(
        <div className="frame">
            <SideBar />
                <div id="mainContentsContainer">
                <Title main={"약관"} sub={"일반 ▶︎ 약관"} />
                <div className="subContentsContainer" >

                    <div style={{fontWeight: 600,color: "gray"}}>
                        {"회원가입 약관"}
                    </div>
                    <div> 
                    {terms.map((term,index)=><TermBox terms={terms} setTerms={setTerms} index={index} />)}
                    </div>
                    <div style={{display: "flex",justifyContent: "flex-end"}}>
                        <div onClick={appendTerm} style={{cursor: "pointer",display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "gray",color: "#FFFFFF" ,padding: "10px",width: "100px",fontWeight: 600}}>
                            {"추가"}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default Term;
import React, { useState } from "react";
import "../css/Main.css";


function NewUser() {

  // container three 데이터
  let [today, setToday] = useState("30,200");// 오늘 
  let [comWithYester, setComWithYester] = useState("+300");// 어제 대비
  let [thisMonth, setThisMonth] = useState("80,019");// 이번달
  let [comWithLastMonth, setComWithLastMonth] = useState("+11,873"); // 지난달 대비 
  let [acculmulated, setAcculmulated] = useState("120,000"); // 누적 회원 수


  return (

    <div class="three">
      <div>
        <div className="font1">신규 사용자 현황</div>
        <img src="/img/newUser.png" />
      </div>
      <div>
        <div className="font2 dataLeftMargin" style={{ marginTop: 60 }}>오늘 {today}</div>
        <div className="font3 dataLeftMargin" >어제대비 {comWithYester}</div>
        <div className="font2 dataLeftMargin" >이번달 {thisMonth}</div>
        <div className="font3 dataLeftMargin" >지난달대비 {comWithLastMonth}</div>
        <div className="font2 dataLeftMargin" >누적회원수 {acculmulated}</div>
      </div>
    </div>
  );
}

export default NewUser;

import React, { useEffect, useState } from "react";
import "../css/all.css";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SideBar from "../SideBar";
import { BsSearch } from 'react-icons/bs';
import { RiFileExcel2Line } from 'react-icons/ri';
import Table from "../../component/Table";
import Modal from "@mui/material/Modal";
import { withRouter } from "react-router-dom";
import Range from "../../component/Range";
import Categories from "../../component/Categories";
import AccountHistory from "../../component/AccountHistory";
import * as api from "../../controller/api";
import Title from "../../component/Title";
import { styled } from "@mui/material";
import {requestMessageModalOpen,requestSearchAccountPush} from "../../redux/system/system";
import {useDispatch,useSelector} from "react-redux";
import { useMediaQuery } from "@mui/material";


export const ModalContainer=styled("div")({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    width: "350px",
    height: "200px",
    borderRadius: "5%",
    // border: "4px solid #FF7F00",
    backgroundColor: "#FFFFFF",
    color: "#000000",
    fontSize: "16px",
    // padding: "10px",
    cursor: "pointer",
    fontWeight: "normal",
    "&:focus":{
      outline: "none"
    }
  });

  export const NoticeDeleteButton=styled("div")({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    width: "60px",
    height: "30px",
    borderRadius: "50px",
    border: "1px solid #ADADAD",
    backgroundColor: "#FFFFFF",
    color: "#000000",
    fontSize: "14px",
    padding: "5px",
    margin: "10px",
    fontWeight: "normal",
    "&:focus":{
      outline: "none"
    },
    "&:hover":{
        backgroundColor: "#F2F2F2"
    }
  });



function Notice(props) {
  let { system } = useSelector(state=>state);
  let dispatch=useDispatch();
  let [record, setRecord] = useState(32); // 검색 결과 갯수;
  let [isNewNoticeModalOpen,setIsNewNoticeModalOpen]=useState(false);
  let [isDeleteNoticeModalOpen,setIsDeleteNoticeModalOpen]=useState(false);
  let [selectedCategory,setSelectedCategory]=useState(0);  //선택된 카테고리
  let [selectedCompany,setSelectedCompnay]=useState(null);
  let [selectedRange,setSelectedRange]=useState(null);  //선택한 범위(버튼 on off 용도)
  let [startRange,setStartRange]=useState(""); //실제 범위(time type:string ex: YYYY-MM-DD) 이날 00시부터
  let [endRange,setEndRange]=useState("")  //실제 범위(time type:string ex: YYYY-MM-DD)  이날 24시까지
  let [tableData,setTableDate]=useState({});  //page,count,filter 3가지(변경되면 바로 쿼리 쏴줘야함)!!!!!
  let [accountId,setAccountId]=useState("");
  let [checkedList,setCheckedList]=useState([]);

  let [newNoticeTitle,setNewNoticeTitle]=useState("");
  let [newNoticeContext,setNewNoticeContext]=useState("");
  let [isNewNoticeDisplay,setIsNewNoticeDisplay]=useState(true);
let [checkedObjectList,setCheckedObjectList]=useState([]);
//   "category_cd": "0002",
//             "title": "주문 샘플 공지11입니다.",
//             "context": "샘플",
//             "display_yn": "Y",
//             "reg_dt": "2021-10-05T06:45:39.000Z",
//             "reg_id": "tmkim",
  let [columns,setColumns]=useState(
    [  
        { field: '0', name: '#', match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
        { field: '1', name: '작성자', match: "reg_id", width: 170, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
        { field: '2', name: '작성일', match: "reg_dt", width: 170, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
        { field: '3', name: '수정자', match: "chg_id", width: 170, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
        { field: '4', name: '수정일', match: "chg_dt", width: 170, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
        { field: '5', name: '제목',match : "reg_dt", width: 200, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
        { field: '6', name: '화면표시',match: "display_yn", width: 200, isSort:true, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: [] },
        { field: '7', name: '',match: "null", width: 100, isSort:false, sortDirection: "none" ,isCheckbox: false ,checkboxList: [], checkboxData: []},
      ]
  );

  let [rows,setRows]=useState([null]);
  let [rowDetail,setRowDetail]=useState([]);
  let [isNeedToSearch,setIsNeedToSearch]=useState(true);
  let match_400=useMediaQuery('(max-width:400px)');

    useEffect(()=>{
        if(checkedList.length>0){
            setCheckedObjectList(checkedList.map((c,ci)=>rowDetail[ci]!== undefined ? c==true ? {seq_no: rowDetail[ci].seq_no, title: rowDetail[ci].title} : undefined : undefined).filter(d=>d!==undefined));
        }
    },[checkedList]);

  useEffect(()=>{
    if(isNeedToSearch==false){
      sendApi();
    }
  },[tableData])

  const sendApi=()=>{
    api.getNotice({
      selectedCategory,
      range: {
        startRange,
        endRange
      },
      tableData,
      accountId
    })
    .then(res=>{
        dispatch(requestSearchAccountPush(accountId));
      if(res.data.data==null || res.data.data.length==0){
        dispatch(requestMessageModalOpen({type:"success",message:`공지사항 내역이 없습니다.`}));
        }
          setRows(res.data.data.map((d,di)=>{
            console.log(d.chg_id)
            console.log(d.chg_dt)
              return [
            tableData.page*tableData.count+di,
            d.reg_id,
            d.reg_dt==null ? null : d.reg_dt.split("T")[0],
            d.chg_id,
            d.chg_dt==null ? null : d.chg_dt.split("T")[0],
            d.title,
            d.display_yn=="Y" ? 'YES' : 'NO',
            "+",
          ]}));
          setRowDetail(
            res.data.data.map((d,di)=>{return {
                reg_id:d.reg_id,
                title:d.title,
                display_yn:d.display_yn,
                context:d.context,
                seq_no: d.seq_no
            }})
          );
      
    })
    .catch(err=>{
      dispatch(requestMessageModalOpen({type:"error",message:err.response?.data?.message}));
    })
  }

  const changeNotice=(data)=>{
    api.changeNotice(data)
    .then(res=>{
        dispatch(requestMessageModalOpen({type:"success",message:res.data?.message}));
        sendApi();
    })
    .catch(err=>{
        dispatch(requestMessageModalOpen({type:"error",message:err.response?.data?.message}));
    });
  }

  const deleteNotice=()=>{
      if(checkedObjectList.length>0){
          api.deleteNotice({deleteArray:checkedObjectList.map(d=>d.seq_no)})
          .then(res=>{
              dispatch(requestMessageModalOpen({type:"success",message:res.data?.message}));
              setIsDeleteNoticeModalOpen(false);
              sendApi();
          })
          .catch(err=>{
              dispatch(requestMessageModalOpen({type:"error",message:err.response?.data?.message}));
          });
      }
  }
  
  const generateNotice=()=>{
    if(newNoticeContext!=="" && newNoticeTitle!==""){
        api.generateNotice({title: newNoticeTitle,context: newNoticeContext, display_yn: isNewNoticeDisplay==true ? "Y" : "N"})
        .then(res=>{
            dispatch(requestMessageModalOpen({type:"success",message:res.data?.message}));
            setNewNoticeContext("");
            setNewNoticeTitle("");
            setIsNewNoticeDisplay(true);
            setIsNewNoticeModalOpen(false);
            sendApi();
        })
        .catch(err=>{
            dispatch(requestMessageModalOpen({type:"error",message:err.response?.data?.message}));
        });
    }else{
        dispatch(requestMessageModalOpen({type: "error", message: "모든 항목을 입력하세요."}))
    }
  }

  return (
    <div className="frame">
      <SideBar />
      <div id="mainContentsContainer">
        <Title main={"공지사항"} sub={"일반 ▶︎ 공지사항"} />

        <div className="subContentsContainer">

            <Categories target={"notice"} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} selectedCompany={null} setSelectedCompnay={null} isNeedToSearch={isNeedToSearch} setIsNeedToSearch={setIsNeedToSearch}/>
            <Range selectedRange={selectedRange} setSelectedRange={setSelectedRange} startRange={startRange} setStartRange={setStartRange} endRange={endRange} setEndRange={setEndRange}/>

            <div className="option4Container">
              <input type="text" placeholder="작성자" value={accountId} 
                onKeyPress={(e)=>{
                  if(e.key=="Enter"){
                    sendApi();
                    setIsNeedToSearch(false);
                  } 
                }}
                onChange={(e)=>{
                  setAccountId(e.target.value)
                }}
              /><span onClick={()=>{sendApi();setIsNeedToSearch(false);}} className="searchIconBox"><BsSearch /></span>
            </div>
            <div style={{display: "flex", justifyContent: "flex-start"}}>
                <span
                    style={{width: "100px",padding: "7px 16px 7px 16px", margin: "6px", border: "2px solid black", textAlign: "center", verticalAlign: "middle",fontWeight: 550,color: "#FFFFFF", backgroundColor: "#000000", cursor: "pointer"}}
                    onClick={()=>{setIsDeleteNoticeModalOpen(true)}}
                >
                    삭제
                </span>
                <span
                    style={{width: "100px",padding: "7px 16px 7px 16px", margin: "6px", border: "2px solid black", textAlign: "center", verticalAlign: "middle",fontWeight: 550,color: "#FFFFFF", backgroundColor: "#000000", cursor: "pointer", marginLeft: "10px"}}
                    onClick={()=>{setIsNewNoticeModalOpen(true)}}
                >
                    신규개시
                </span>
            </div>
            <AccountHistory setAccountId={setAccountId}/>
        </div>
        <Table checkedList={[checkedList,setCheckedList]} isCheckRow={true} deleteHandle={deleteNotice} submitHandle={changeNotice} rowDetail={[rowDetail,setRowDetail]} isAccordion={"notice"}  head={[columns,setColumns]} body={[rows,setRows]} control={[tableData,setTableDate]} selectedCompany={selectedCompany} isNeedToSearch={isNeedToSearch} setIsNeedToSearch={setIsNeedToSearch} />
      </div>
        <Modal
            open={isNewNoticeModalOpen}
            onClose={()=>{setIsNewNoticeModalOpen(false)}}
            style={{display: "flex", width: "100vw", justifyContent: "center", alignItems: "center"}}
        >
            <ModalContainer style={{height: match_400 ? "300px" : "400px"}}>
                <div
                    style={{display: "flex", flexDirection: "column", alignItems: 'center'}}
                >
                    <div style={{width: "100%",display: "flex",}}>
                        <label htmlFor="newTitle" style={{width: "40px",paddingTop: "4px"}} >제목</label>
                        <input id="newTitle" className="newNoticeInput" type="text" value={newNoticeTitle} onChange={(e)=>{setNewNoticeTitle(e.target.value)}}/>
                    </div>
                    <div style={{width: "100%",display: "flex",height:match_400 ? "100px" : "200px"}}>
                        <label htmlFor="newContext" style={{width: "40px",paddingTop: "4px"}} >내용</label>
                        <textarea id="newContext" className="newNoticeTextarea" value={newNoticeContext} onChange={(e)=>{setNewNoticeContext(e.target.value)}}/>
                    </div>
                    {/* isNewNoticeDisplay */}
                    <div style={{display: "flex", flexDirection: "row", alignItems: 'center', margin: "10px 0px 10px 0px"}}>
                        <span style={{cursor: "pointer"}} onClick={()=>{setIsNewNoticeDisplay(true)}}>{"화면표시YES "}{isNewNoticeDisplay ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}</span>
                        <span style={{cursor: "pointer"}} onClick={()=>{setIsNewNoticeDisplay(false)}}>{"화면표시NO  "}{!isNewNoticeDisplay ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}</span>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", alignItems: 'center'}}>
                        <span>
                            <NoticeDeleteButton onClick={generateNotice}>
                                등록
                            </NoticeDeleteButton>
                        </span>
                        <span>
                            <NoticeDeleteButton onClick={()=>{setIsNewNoticeModalOpen(false)}}>
                                닫기
                            </NoticeDeleteButton>
                        </span>
                    </div>
                </div>
            </ModalContainer>   
        </Modal>
        <Modal
            open={isDeleteNoticeModalOpen}
            onClose={()=>{setIsDeleteNoticeModalOpen(false)}}
            style={{display: "flex", width: "100vw", justifyContent: "center", alignItems: "center"}}
        >
            <ModalContainer>
                    
                <div
                    style={{display: "flex", flexDirection: "column", alignItems: 'center'}}
                >
                    {
                        checkedObjectList.length==0 ? 
                        "삭제할 항목을 선택하세요." :
                        checkedObjectList.length==1 ? 
                        checkedObjectList[0].title +" 공지사항을 삭제합니다." :
                        checkedObjectList[0].title+" 외+"+(checkedObjectList.length-1)+" 공지사항을 삭제합니다."
                    }
                    <div style={{display: "flex", flexDirection: "row", alignItems: 'center', marginTop: "40px"}}>
                        <span>
                            <NoticeDeleteButton onClick={deleteNotice}>
                                삭제
                            </NoticeDeleteButton>
                        </span>
                        <span>
                            <NoticeDeleteButton onClick={()=>{setIsDeleteNoticeModalOpen(false)}}>
                                닫기
                            </NoticeDeleteButton>
                        </span>
                    </div>
                </div>
                
            </ModalContainer>   
        </Modal>
        {/* {console.log(tableData.page)}
        {console.log(checkedList)} */}
    </div >
  );
}

export default withRouter(Notice);
import React, { useRef, useState } from "react";
import * as api from "../controller/api";
import { useSelector, useDispatch } from "react-redux";
import "./css/Login.css";
import {requestUserSettingAction} from "../redux/user/user" ;
import { requestMessageModalOpen } from "../redux/system/system";
import {withRouter} from "react-router-dom";
function Login(props) {
  const dispatch=useDispatch();
  const { user } = useSelector((state)=>state);
  const idInput = useRef();
  const pwdInput = useRef();
  let [userId,setUserId]=useState("");
  let [userPw,setUserPw]=useState("");
  let [errorType,setErrorType]=useState("");

  function isEmpty(Value) {
    if (Value === "") {
      return true
    }
  };

  function loginHandler() {
    if(userId==""){
      setErrorType("id");
    }else if(userPw==""){
      setErrorType("pw");
    }else{
      api.login({account:userId,password:userPw})
      .then(res=>{
        dispatch(
          requestUserSettingAction({userEmail: res.data.data.userEmail})
        )
        localStorage.setItem("sellerbox-token",res.data.data.token);
        setErrorType("");
        props.history.push("/sellerbox-admin.cplat.io/main");
      })
      .catch(err=>{
        dispatch(requestMessageModalOpen({type: "error", message: err.response.data.message}));
        setErrorType("api");
      })
      .finally(()=>{
        setUserId("");
        setUserPw("");
      });
    }
  };

  return (
    <div id="Login">
      <div id="logoText">셀러의 성공을 돕는 셀러박스</div>
      <input id="id" ref={idInput} value={userId} className="loginInput" type="text" placeholder="아이디" onChange={(e)=>{setUserId(e.target.value)}} style={{ borderColor: errorType=="id" || errorType=="api" ? "red" : "black" }}></input>
      <input id="password" ref={pwdInput} value={userPw} className="loginInput" type="password" placeholder="비밀번호" onChange={(e)=>{setUserPw(e.target.value)}} style={{ borderColor: errorType=="pw" || errorType=="api" ? "red" : "black" }}></input>
      {errorType=="id" && <div className="msgEmpty"><span>아이디를 입력해주세요</span></div>}
      {errorType=="pw" && <div className="msgEmpty"><span>비밀번호를 입력해주세요</span></div>}
      {errorType=="api" && <><div className="msgEmpty"><span>아이디 또는 비밀번호가 잘못 입력 되었습니다.</span></div><div className="msgEmpty"><span>아이디와 비밀번호를 정확히 입력해 주세요.</span></div></>}
      <button id="loginBtn" onClick={loginHandler}>로그인</button>
    </div>
  );
}

export default withRouter(Login);



import React from "react";
// import { Container, Row, Col } from 'react-bootstrap';
import "./css/Main.css";
import SideBar from "./SideBar";
import Download from "./mainComponents/Download";
import Review from "./mainComponents/Review";
import NewUser from "./mainComponents/NewUser";
import WithdrawalUser from "./mainComponents/WithdrawalUser";
import CurrentUser from "./mainComponents/CurrentUser";

function Main() {

  return (
    <div id="Main">
      <SideBar />
      <div id="contentsContainer">
        <Download />
        <Review />
        <NewUser />
        <WithdrawalUser />
        <CurrentUser />
      </div>
    </div>
  );
}

export default Main;




import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import "./css/Header.css";
import {useSelector,useDispatch} from "react-redux";
import {requestUserDeleteAction} from "../redux/user/user";
import {withRouter} from "react-router-dom";
function Header(props) {
  const dispatch=useDispatch();
  const {user}=useSelector(state=>state);
  let [isLoggedIn,setIsLoggedIn]=useState(localStorage.getItem("sellerbox-token"));
  let [userEmail,setUserEmail]=useState(user.userEmail ? user.userEmail : null);
  function isLogin() {
    // Login 여부 체크 로직 Login = true or Login = false
  }

  useEffect(()=>{
    if(!isLoggedIn){
      props.history.push("/sellerbox-admin.cplat.io/login")
    }
  },[props.history.location.pathname]);

  useEffect(()=>{
    if(user.userEmail){
      setIsLoggedIn(true);
    }
  },[user])

  const handleLogout=()=>{
    setIsLoggedIn(false);
    localStorage.removeItem("sellerbox-token");
    dispatch(
      requestUserDeleteAction()
    );
    props.history.push("/sellerbox-admin.cplat.io/login");
  }

  const handleLogin=()=>{
    props.history.push("/sellerbox-admin.cplat.io/login");
  }

  const handleLogo=()=>{
    if(isLoggedIn){
      props.history.push("/sellerbox-admin.cplat.io/main");
    }else{
      props.history.push("/sellerbox-admin.cplat.io/login");
    }
  }

  return (
    <div id="header">
      <div id="Logos">
          <span id="Logo" onClick={handleLogo} style={{cursor: "pointer"}}>LOGO</span>
        <span id="LogeText">SellerBox Management Portal</span>
      </div>
      <div>
        {userEmail ? <span id="userEmail">{userEmail}</span> : null}
          {isLoggedIn ? <span id="toLogin" style={{ color: "black",cursor: "pointer" }} onClick={handleLogout} >로그아웃</span> : <span id="toLogin"style={{cursor: "pointer"}}  onClick={handleLogin}>로그인</span>}
      </div>
    </div>
  );
}

export default withRouter(Header);
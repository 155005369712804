import axios from "axios";

// let backendUrl="http://localhost:4001/";
let backendUrl="https://social.cplat.io:4001/";
// let backendUrl="http://racgoo.p-e.kr:33333/";

export const login=(data)=>{
    let query=backendUrl+"login/"
    let result = axios.post(query,data, {
        // timeout: 600000,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "access-control-allow-methods": "POST",
        },
      });
      return result;
};

export const getBuyer=(data)=>{
    data.token=localStorage.getItem("sellerbox-token");
    let query=backendUrl+"service/getBuyer"
    let result = axios.post(query,data, {
        // timeout: 600000,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "access-control-allow-methods": "POST",
        },
      });
      return result;
};

export const getSales=(data)=>{
    data.token=localStorage.getItem("sellerbox-token");
    let query=backendUrl+"service/getSales"
    let result = axios.post(query,data, {
        // timeout: 600000,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "access-control-allow-methods": "POST",
        },
      });
      return result;
};

export const getInquiry=(data)=>{
  data.token=localStorage.getItem("sellerbox-token");
  let query=backendUrl+"user/getInquiry"
  let result = axios.post(query,data, {
      // timeout: 600000,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "access-control-allow-methods": "POST",
      },
    });
    return result;
};



export const getNotice=(data)=>{
  data.token=localStorage.getItem("sellerbox-token");
  let query=backendUrl+"general/getNotice"
  let result = axios.post(query,data, {
      // timeout: 600000,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "access-control-allow-methods": "POST",
      },
    });
    return result;
};

export const changeNotice=(data)=>{
  data.token=localStorage.getItem("sellerbox-token");
  let query=backendUrl+"general/changeNotice"
  let result = axios.post(query,data, {
      // timeout: 600000,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "access-control-allow-methods": "POST",
      },
    });
    return result;
}

export const changeInquiry=(data)=>{
  data.token=localStorage.getItem("sellerbox-token");
  let query=backendUrl+"user/changeInquiry"
  let result = axios.post(query,data, {
      // timeout: 600000,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "access-control-allow-methods": "POST",
      },
    });
    return result;
}

export const deleteNotice=(data)=>{
  data.token=localStorage.getItem("sellerbox-token");
  let query=backendUrl+"general/deleteNotice"
  let result = axios.post(query,data, {
      // timeout: 600000,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "access-control-allow-methods": "POST",
      },
    });
    return result;
}

export const generateNotice=(data)=>{
  data.token=localStorage.getItem("sellerbox-token");
  let query=backendUrl+"general/generateNotice"
  let result = axios.post(query,data, {
      // timeout: 600000,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "access-control-allow-methods": "POST",
      },
    });
    return result;
}

export const sendPushNotification=(data)=>{
  data.token=localStorage.getItem("sellerbox-token");
  let query=backendUrl+"pushNotice/sendPushNotification"
  let result = axios.post(query,data, {
      // timeout: 600000,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "access-control-allow-methods": "POST",
      },
    });
    return result;
};

export const getPushHistory=(data)=>{
  data.token=localStorage.getItem("sellerbox-token");
  let query=backendUrl+"pushNotice/getPushHistory"
  let result = axios.post(query,data, {
      // timeout: 600000,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "access-control-allow-methods": "POST",
      },
    });
    return result;
};



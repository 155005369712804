import React, { useEffect,useState } from "react";
import SideBar from "../SideBar";
import Title from "../../component/Title";
import "../css/all.css";
import axios from "axios";
import { Link } from "react-router-dom";
const Market = () => {
    const [ACCESS_TOKEN,SET_ACCESS_TOKEN]=useState("");
  function loginWithKakao() {
    // window.Kakao.Auth.authorize({
    //   redirectUri: "http://localhost:3000",
    //   prompts: "login",
    // });
    
  }

  const kakaoHeader = {
        // 'Authorization': 'CSsybEsGcGr0NmcIJUpsfS79jfsiyuQ5kOFXHQopyNoAAAF82jRPrg',
        'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
    };

    function showAuth(){
        console.log( window?.Kakao?.Auth?.getAccessToken())
    }

    function logout(){
        // window.Kakao.cleanup();
        // window.Kakao.init("fe3e5712fe3356a869e3b2fc4c1ebd37");
        // window.Kakao.Auth.logout();
        // window.Kakao.Auth.cleanup();

        axios.post("https://kapi.kakao.com/v1/user/unlink",{},{headers:{Authorization: `Bearer ${window?.Kakao?.Auth?.getAccessToken()}`}})
    }

    function kakaoLogin() {
        // axios.get(`https://kauth.kakao.com/oauth/authorize?client_id=4cf5ea11b7eb9878ee3f64ef87b69c9f&redirect_uri=http://localhost:3000&response_type=code`)
        // .then(data=>console.log(data))
        // .catch(err=>console.log(err))
        window.Kakao.Auth.loginForm((res)=>{console.log(res)});
        // window.Kakao.Auth.login({
        //   success: function (response) {
        //     setToken(response.access_token);
        //     console.log(response.access_token)
        //     window.Kakao.API.request({
        //       url: '/v2/user/me',
        //       success: function (response) {
        //           console.log(response)
        //       },
        //       fail: function (error) {
        //         console.log(error)
        //       },
        //     })
        //   },
        //   fail: function (error) {
        //     console.log(error)
        //   },
        // })
      }
    function tokenToUser(){
        
    }

const getKakaoToken = async (code) => {
    console.log('loginWithKakao');
    console.log(window?.Kakao?.Auth?.getAccessToken())
    console.log(window.Kakao.Auth)
    // try {
    //     const data = {
    //         grant_type: 'authorization_code',
    //         client_id: '4cf5ea11b7eb9878ee3f64ef87b69c9f',
    //         redirect_uri: 'http://localhost:8080/auth',
    //         code: "CSsybEsGcGr0NmcIJUpsfS79jfsiyuQ5kOFXHQopyNoAAAF82jRPrg",
    //     };
    //     const queryString = Object.keys(data)
    //         .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
    //         .join('&');
    //     const result = await axios.post('https://kauth.kakao.com/oauth/token', queryString, { headers: kakaoHeader });
    //     console.log('카카오 토큰', result);
    //     return result;
    // } catch (e) {
    //     return console.log(e.response);
    // }
};



  function displayToken() {
    const token = getCookie("reauthenticate-access-token");
    console.log(`token: ${token}`)
    if (token) {
        window.Kakao.Auth.setAccessToken(token);
        window.Kakao.Auth.getStatusInfo(({ status }) => {
        if (status === "connected") {
          document.getElementById("token-result").innerText =
            "login success. token: " + window.Kakao.Auth.getAccessToken();
        } else {
            window.Kakao.Auth.setAccessToken(null);
        }
      });
    }
  }
  function getCookie(name) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  return (
    <>
    <div className="frame">
      <SideBar />
      <div id="mainContentsContainer">
        <Title main={"매출"} sub={"서비스 ▶︎ 매출"} />
        <div className="subContentsContainer">
            <a href={`https://kauth.kakao.com/oauth/authorize?client_id=4cf5ea11b7eb9878ee3f64ef87b69c9f&redirect_uri=http://localhost:3000&response_type=code`} > aa</a>
            <button onClick={()=>{
                kakaoLogin();
                }}>
                    sadfsdafsdaadsf
            </button>
            <button onClick={()=>{
                tokenToUser();
                }}>
                    Token to User
            </button>

            <button onClick={()=>{
                showAuth();
                }}>
                    show auth
            </button>
            <button onClick={()=>{
                logout();
                }}>
                    logout
            </button>
            <div onClick={loginWithKakao}>
                <img
                src="//k.kakaocdn.net/14/dn/btqCn0WEmI3/nijroPfbpCa4at5EIsjyf0/o.jpg"
                width="222"
                />
            </div>
      <p id="token-result"></p>
      </div>
      </div>
      </div>
    </>
  );
};

export default Market;
